import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { TagData, tagsApi } from '../api/simple_api';

let template = require('../../templates/tags.html').default;

class TagsScreen implements ListLoaderDelegate<TagData> {
    fetch(params: ListRequestParams) {
        return tagsApi.list(params);
    }

    instantiate(data: TagData) {
        return data;
    }

    getEditUrl(entity: TagData): string {
        return '/tags/' + entity.id + '/';
    }

    remove(id: string) {
        return tagsApi.remove(id);
    }

    canRemove(data: TagData) {
        return true;
    }
}

export let tags = { name: 'tags', viewModel: TagsScreen, template: template };

ko.components.register(tags.name, tags);
