import * as ko from 'knockout';

import { BusinessLineData, SuggestedBusinessLineData, isSuggested, BusinessLineDevelopmentFileData, BusinessLineDevelopmentData, BusinessLineDevelopmentRecordData, FactorDefData, ProjectCategoryData, ProductFactorDefData, FactorValue, FactorDocumentValue, getProductDocUploadEndpoint, ProductBudgetData, ProductFileData, CountryData, CropData, UserData, PartnerData, FactorValuesDict, BusinessLineIdsData, BusinessLineCommentData, BusinessLineSampleDescrData, TagData, BusinessLineTestSubjectData, getBusinessLineTitleUploadEndpoint, BusinessLinePublicMessageData, SiteData, PreCommercialProductionData, CommercialProductionData, LinkedTrial } from '../api/simple_api';
import { serializeDate, parseDate, parseDateTime } from '@core/api/serialization';
import { FileUploadDelegate } from '@core/components/basic_widgets';
import { CloudStorageUploadDelegate, CloudStorageUpload, FileUploadEndpoint } from '@core/cloud_storage_upload';
import { asI18nText, translate } from '@core/i18n_text';
import { getFactorDefSearch, getCountrySearch, getCropSearch, getProjectCategorySearch, getUserSearch, getPartnerSearch, getTagSearch, getSiteSearch } from './helpers/search_configs';
import { OrderedEntities } from './helpers/ordered_entities';
import { readDecimal, tryFormatDate, findById, tryFormatDateTime, toDict } from '@core/utils';
import { makeChecklist, checklistToData, ChecklistUserType, CHECKLIST_SUPERVISOR_USER_TYPE } from './business_line_checklist';
import i18n from '@core/i18n';
import { userRole } from 'app_session';
import { session } from '@core/session';
import { confirmDialog } from '@core/components/confirm_dialog';
import { ImageUpload } from '@core/image_upload';
import { BarChartConfig } from 'ko_bindings/bar_chart';
import { businessLinesApi } from "../api/simple_api";
import { downloadBlob } from '@core/utils';

export class BusinessLine {
    uploadPictureText = i18n.t('Upload picture')();
    uploadDocumentText = i18n.t('Upload document')();

    id = ko.observable<string | null>(null);

    excelExportError = ko.observable<Boolean>(false);

    potentialAdoptionOptions = [
        { title: i18n.t('Select')(), value: '' },
        { title: i18n.t('Very low')(), value: 1 },
        { title: i18n.t('Low')(), value: 2 },
        { title: i18n.t('High')(), value: 3 },
        { title: i18n.t('Very high')(), value: 4 },
    ];

    hasTPP = false;
    linkedTrials: LinkedTrial[] = [];

    name = ko.observable('').extend({ required: true, serverError: true });
    website = ko.observable('').extend({ required: false, serverError: true });
    public = ko.observable(false);
    archived = ko.observable(false);
    country = ko.observable<CountryData | null>(null).extend({
        required: true
    });
    crops = ko.observableArray<CropData>();
    categories = ko.observableArray<ProjectCategoryData>().extend({ required: true });
    tags = ko.observableArray<TagData>();
    owner = ko.observable<UserData | null>(null).extend({ required: true });
    editor = ko.observable<UserData | null>(null);
    supervisor = ko.observable<UserData | null>(null);
    viewers = ko.observableArray<UserData>();
    dashboardViewers = ko.observableArray<UserData>();
    partners = ko.observableArray<PartnerData>();
    relevantReason = ko.observable('');
    goal = ko.observable('');
    hypothesis = ko.observable('');
    taskIdentification = ko.observable('');

    targetedMarket = ko.observable('');
    potentialAdoption = ko.observable<number | ''>('');
    potentialAdoptionReason = ko.observable('');
    marketSize = ko.observable('');

    promotingEquality = ko.observable('');
    alignFramework = ko.observable('');

    phase1ChangedAt = ko.observable<Date | null>(null).extend({ required: true });
    phase1Comment = ko.observable('');

    sampleDescriptions = ko.observableArray<BusinessLineSampleDescr>();
    orderedSampleDescriptions: OrderedEntities<BusinessLineSampleDescr>;
    testSubjects = ko.observableArray<BusinessLineTestSubject>();
    orderedTestSubjects: OrderedEntities<BusinessLineTestSubject>;
    sourcesRequirements = ko.observable('');
    proposedDuration = ko.observable('').extend({ number: true, serverError: true });
    budgets = ko.observableArray<ProductBudget>();
    potentialMarket = ko.observable('');

    phase2ChangedAt = ko.observable<Date | null>(null);
    phase2Comment = ko.observable('');

    developments = ko.observableArray<BusinessLineDevelopment>();
    orderedDevelopments: OrderedEntities<BusinessLineDevelopment>;

    fieldtrialsExpansion = ko.observable(false);
    fieldtrialsExpansionComment = ko.observable('').extend({
        required: { onlyIf: () => this.fieldtrialsExpansion() }
    });
    phase4PotentialMarkets = ko.observable(false);
    phase4PotentialMarketsComment = ko.observable('').extend({
        required: { onlyIf: () => this.phase4PotentialMarkets() }
    });
    benefitsEstimation = ko.observable('');
    suppliers = ko.observable('');
    training = ko.observable('');
    infrastructure = ko.observable('');
    regulation = ko.observable('');

    phase4ChangedAt = ko.observable<Date | null>(null);
    phase4Comment = ko.observable('');

    productProfile = ko.observable('');
    externalInvestors = ko.observable(false);
    externalInvestorsComment = ko.observable('').extend({
        required: { onlyIf: () => this.externalInvestors() }
    });
    nextEvaluationTimeFrame = ko.observable('');
    profileDoc = ko.observable<string | null>(null);
    profileDocUrl = ko.observable<string | undefined>('');
    profileDocUserFileName = '';

    phase5ChangedAt = ko.observable<Date | null>(null);
    phase5Comment = ko.observable('');

    discardChangedAt = ko.observable<Date | null>(null);
    discardComment = ko.observable('');

    files = ko.observableArray<ProductFileData>();
    comments = ko.observableArray<BusinessLineComment>();

    publicMessages = ko.observableArray<BusinessLinePublicMessageData>();
    deletedPublicMessages: string[] = [];

    tppResultsChart = ko.observable<BarChartConfig>(null);
    preCommercialProductions = ko.observableArray<PreCommercialProductionData>();
    commercialProductions = ko.observableArray<CommercialProductionData>();

    reducedCropLossChecked = ko.observable(false)
    reducedPostharvestLossChecked = ko.observable(false)
    incomeDiversificationChecked = ko.observable(false)
    useResourcesMoreEfficientlyChecked = ko.observable(false)
    ecosystemBiodiversityChecked = ko.observable(false)
    enhanceSoilHealthChecked = ko.observable(false)
    reducedGhgEmissionsChecked = ko.observable(false)
    increaseProfitabilityChecked = ko.observable(false)

    reducedCropLoss = ko.observable('').extend({
        required: { onlyIf: () => this.reducedCropLossChecked() }, serverError: true 
    });
    reducedPostharvestLoss = ko.observable('').extend({
        required: { onlyIf: () => this.reducedPostharvestLossChecked() }, serverError: true 
    });
    incomeDiversification = ko.observable('').extend({
        required: { onlyIf: () => this.incomeDiversificationChecked() }, serverError: true 
    });
    useResourcesMoreEfficiently = ko.observable('').extend({
        required: { onlyIf: () => this.useResourcesMoreEfficientlyChecked() }, serverError: true 
    });
    ecosystemBiodiversity = ko.observable('').extend({
        required: { onlyIf: () => this.ecosystemBiodiversityChecked() }, serverError: true 
    });
    enhanceSoilHealth = ko.observable('').extend({
        required: { onlyIf: () => this.enhanceSoilHealthChecked() }, serverError: true 
    });
    reducedGhgEmissions = ko.observable('').extend({
        required: { onlyIf: () => this.reducedGhgEmissionsChecked() }, serverError: true 
    });
    increaseProfitability = ko.observable('').extend({
        required: { onlyIf: () => this.increaseProfitabilityChecked() }, serverError: true 
    });

    reducedCropLossResult = ko.observable('').extend({
        serverError: true 
    });
    reducedPostharvestLossResult = ko.observable('').extend({
        serverError: true 
    });
    incomeDiversificationResult = ko.observable('').extend({
        serverError: true 
    });
    useResourcesMoreEfficientlyResult = ko.observable('').extend({
        serverError: true 
    });
    ecosystemBiodiversityResult = ko.observable('').extend({
        serverError: true 
    });
    enhanceSoilHealthResult = ko.observable('').extend({
        serverError: true 
    });
    reducedGhgEmissionsResult = ko.observable('').extend({
        serverError: true 
    });
    increaseProfitabilityResult = ko.observable('').extend({
        serverError: true 
    });
    externalID = ko.observable('').extend({
        serverError: true
    });

    countrySearch = getCountrySearch(this.country);
    cropsSearch = getCropSearch(this.crops);
    categoriesSearch = getProjectCategorySearch(this.categories);
    tagsSearch = getTagSearch(this.tags);
    ownerSearch = getUserSearch(this.owner);
    editorSearch = getUserSearch(this.editor);
    supervisorSearch = getUserSearch(this.supervisor);
    viewersSearch = getUserSearch(this.viewers);
    dashboardViewersSearch = getUserSearch(this.dashboardViewers);
    partnersSearch = getPartnerSearch(this.partners);

    profileDocCloudDelegate: CloudStorageUploadDelegate = {
        canReuseEndpoint: true,
        getUploadEndpoint: (contentType: string): Promise<FileUploadEndpoint> => {
            return getProductDocUploadEndpoint(contentType);
        },
        onFileUploaded: (userFileName: string, fileName: string, publicURL: string, contentType: string) => {
            this.profileDoc(fileName);
            this.profileDocUrl(publicURL);
            this.profileDocUserFileName = userFileName;
        }
    };
    profileDocUpload = new CloudStorageUpload(this.profileDocCloudDelegate);
    profileDocDelegate: FileUploadDelegate = {
        fileUploadError: this.profileDocUpload.fileUploadError,
        onFileContents: (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
            return this.profileDocUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
        }
    };

    titlePictureUpload = new ImageUpload({
        getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
            return getBusinessLineTitleUploadEndpoint(contentType);
        }
    });

    checklist = ko.observable(makeChecklist([]));

    selectedDevelopment = ko.observable<BusinessLineDevelopment | null>(null);

    isOwner = ko.pureComputed(() => {
        return this.isSupervisor() || (this.owner() && this.owner().id === session.userId());
    });

    isEditor = ko.pureComputed(() => {
        return this.isSupervisor() || (this.editor() && this.editor().id === session.userId());
    });

    isSupervisor = ko.pureComputed(() => {
        return (!this.id() || userRole() === 'admin' || (this.supervisor() && this.supervisor().id === session.userId()));
    });

    canEdit = ko.pureComputed(() => {
        return this.isOwner() || this.isSupervisor() || this.isEditor();
    });
    canEditImported = ko.pureComputed(() => this.canEdit() && !this.hasTPP);
    canEditImportedOrWithLinkedTrials = ko.pureComputed(() => this.canEdit() && !this.hasTPP && this.linkedTrials.length == 0);

    sampleDescrSortingOption = ko.observable<'order' | 'location' | 'control'>('order');
    sortedSampleDescrs = ko.pureComputed(() => {
        let res = this.sampleDescriptions().slice();
        let type = this.sampleDescrSortingOption();

        res.sort((a, b) => {
            if (type === 'order') {
                return a.order() - b.order();
            } else if (type === 'location') {
                let cmp = translate(a.site()?.name_json).localeCompare(translate(b.site()?.name_json));
                if (cmp !== 0) {
                    if (!a.site()) {
                        return 1;
                    } else if (!b.site()) {
                        return -1;
                    }
                }
                return cmp;
            } else {
                let orderA = parseInt(a.testSubjectOrder(), 10) || 0;
                let orderB = parseInt(b.testSubjectOrder(), 10) || 0;
                return orderA - orderB;
            }
        });

        return res;
    });

    trialLinks = ko.pureComputed(() => {
        //@ts-ignore
        const fieldtrialsUrl: string = {
            local: "http://localhost:8080/",
            qa: "https://fieldtrials-staging.sfsa-tools.org/",
            production: "https://fieldtrials.sfsa-tools.org/",
            }[process.env.CONNECT_TO];
        return this.linkedTrials.map(trial => ({
            title: trial.name,
            link: `${fieldtrialsUrl}t/ft/trials/${trial.fieldtrials_trial_id}`
        }))
    });

    constructor(public user: UserData, data: BusinessLineData | SuggestedBusinessLineData, private suggestedBusinessLineId: string) {
        if (data) {
            this.name(data.name);
            this.country(data.country);

            if (isSuggested(data)) {
                // Add information from suggested innovation
                // Keep commented until there's info about fields matching (e.g. categories)
                // this.phase1Comment(data.additional_information);
            } else {
                this.categories(data.categories);
                this.hypothesis(data.hypothesis);
                this.website(data.website);
                this.hasTPP = data.has_tpp;
                this.tppResultsChart(data.tpp_results_chart);
                this.preCommercialProductions(data.pre_commercial_productions ?? []);
                this.commercialProductions(data.commercial_productions ?? []);
                this.linkedTrials = data.linked_trials;

                this.id(data.id);

                this.public(data.public);
                this.archived(data.archived);
                this.tags(data.tags);
                this.crops(data.crops);
                this.owner(data.owner);
                this.editor(data.editor);
                this.supervisor(data.supervisor);
                this.viewers(data.viewers);
                this.dashboardViewers(data.dashboard_viewers)
                this.partners(data.partners);
                this.relevantReason(data.relevant_reason);
                this.goal(data.goal);
                this.taskIdentification(data.task_identification);
                this.targetedMarket(data.targeted_market);
                this.marketSize(data.market_size);
                this.potentialAdoption(data.potential_adoption || '');
                this.potentialAdoptionReason(data.potential_adoption_reason);

                this.promotingEquality(data.promoting_equality);
                this.alignFramework(data.align_framework);

                this.phase1ChangedAt(parseDate(data.phase_1_changed_at));
                this.phase1Comment(data.phase_1_comment);

                if(data.test_subjects) {
                    this.testSubjects(data.test_subjects.map(data => new BusinessLineTestSubject(data)));
                }
                
                if(data.sample_descriptions) {
                    this.sampleDescriptions(data.sample_descriptions.map(descr => new BusinessLineSampleDescr(this.testSubjects, descr)));
                }
                this.sourcesRequirements(data.sources_requirements);
                this.proposedDuration(readDecimal(data.proposed_duration));
                this.budgets(data.budgets.sort((a, b) => (a?.order ?? 0) - (b?.order ?? 0)).map(budget => new ProductBudget(budget)));
                this.potentialMarket(data.potential_market);
                this.titlePictureUpload.fileName = data.title_picture;
                this.titlePictureUpload.picturePublicURL(data.title_picture_url);

                this.phase2ChangedAt(parseDate(data.phase_2_changed_at));
                this.phase2Comment(data.phase_2_comment);

                this.developments(data.developments.map(dev => new BusinessLineDevelopment(this.sampleDescriptions, this.categories, dev)));

                this.fieldtrialsExpansion(data.fieldtrials_expansion);
                this.fieldtrialsExpansionComment(data.fieldtrials_expansion_comment);
                this.phase4PotentialMarkets(data.phase_4_potential_markets);
                this.phase4PotentialMarketsComment(data.phase_4_potential_markets_comment);
                this.benefitsEstimation(data.benefits_estimation);
                this.suppliers(data.suppliers);
                this.training(data.training);
                this.infrastructure(data.infrastructure);
                this.regulation(data.regulation);

                this.phase4ChangedAt(parseDate(data.phase_4_changed_at));
                this.phase4Comment(data.phase_4_comment);

                this.productProfile(data.product_profile);
                this.externalInvestors(data.external_investors);
                this.externalInvestorsComment(data.external_investors_comment);
                this.nextEvaluationTimeFrame(data.next_evaluation_time_frame);
                this.profileDocUrl(data.profile_doc_url);
                this.profileDocUserFileName = data.profile_doc_user_file_name;

                this.phase5ChangedAt(parseDate(data.phase_5_changed_at));
                this.phase5Comment(data.phase_5_comment);

                this.discardChangedAt(parseDate(data.discard_changed_at));
                this.discardComment(data.discard_comment);

                this.checklist(makeChecklist(data.checklist));

                this.files(data.files || []);
                this.comments((data.comments || []).map(data => new BusinessLineComment(user, this.owner, this.editor, data.phase, data.step, data)));

                this.publicMessages(data.public_messages ?? []);
                this.publicMessages.sort((a, b) => -a.created_at.localeCompare(b.created_at));

                this.reducedCropLossChecked(!!data.reduced_crop_loss)
                this.reducedPostharvestLossChecked(!!data.reduced_postharvest_loss)
                this.incomeDiversificationChecked(!!data.income_diversification)
                this.useResourcesMoreEfficientlyChecked(!!data.use_resources_more_efficiently)
                this.ecosystemBiodiversityChecked(!!data.ecosystem_biodiversity)
                this.enhanceSoilHealthChecked(!!data.enhance_soil_health)
                this.reducedGhgEmissionsChecked(!!data.reduced_ghg_emissions)
                this.increaseProfitabilityChecked(!!data.increase_profitability)

                this.reducedCropLoss(data.reduced_crop_loss)
                this.reducedPostharvestLoss(data.reduced_postharvest_loss)
                this.incomeDiversification(data.income_diversification)
                this.useResourcesMoreEfficiently(data.use_resources_more_efficiently)
                this.ecosystemBiodiversity(data.ecosystem_biodiversity)
                this.enhanceSoilHealth(data.enhance_soil_health)
                this.reducedGhgEmissions(data.reduced_ghg_emissions)
                this.increaseProfitability(data.increase_profitability)

                this.reducedCropLossResult(data.reduced_crop_loss_result)
                this.reducedPostharvestLossResult(data.reduced_postharvest_loss_result)
                this.incomeDiversificationResult(data.income_diversification_result)
                this.useResourcesMoreEfficientlyResult(data.use_resources_more_efficiently_result)
                this.ecosystemBiodiversityResult(data.ecosystem_biodiversity_result)
                this.enhanceSoilHealthResult(data.enhance_soil_health_result)
                this.reducedGhgEmissionsResult(data.reduced_ghg_emissions_result)
                this.increaseProfitabilityResult(data.increase_profitability_result)
                this.externalID(data.external_id)
            }
        }

        this.orderedTestSubjects = new OrderedEntities(this.testSubjects);
        this.orderedSampleDescriptions = new OrderedEntities(this.sampleDescriptions);
        this.orderedDevelopments = new OrderedEntities(this.developments);
        if (this.developments().length > 0) {
            this.selectDevelopment(this.developments()[0])
        }
    }

    updateIds(savedData: BusinessLineData, data: BusinessLineIdsData) {
        if (this.canEdit()) {
            this.id(data.id);
            savedData.id = data.id;
            data.test_subjects.forEach((id, idx) => {
                this.testSubjects()[idx].id = id;
                savedData.test_subjects[idx].id = id;
            })
            data.sample_descriptions.forEach((id, idx) => {
                this.sampleDescriptions()[idx].id = id;
                savedData.sample_descriptions[idx].id = id;
            });
            data.budgets.forEach((id, idx) => {
                this.budgets()[idx].id(id);
                savedData.budgets[idx].id = id;
            });
            data.files.forEach((id, idx) => {
                this.files()[idx].id = id;
                savedData.files[idx].id = id;
            });
            data.developments.forEach((dev, idx) => {
                let devModel = this.developments()[idx];
                let devData = savedData.developments[idx];

                devModel.id(dev.id);
                devData.id = dev.id;

                dev.factors.forEach((id, idx) => {
                    devModel.factors()[idx].id(id);
                    devData.factors[idx].id = id;
                });
                dev.files.forEach((id, idx) => {
                    devModel.files()[idx].id = id;
                    devData.files[idx].id = id;
                });
                dev.records.forEach((id, idx) => {
                    devModel.records()[idx].id(id);
                    devData.records[idx].id = id;
                });
            });
        }

        for (let { client_id, server_id } of data.comments) {
            for (let comment of this.comments()) {
                if (comment.clientId === client_id) {
                    comment.id = server_id;
                }
            }
            for (let comment of savedData.comments) {
                if (comment.client_id === client_id) {
                    comment.id = server_id;
                }
            }
        }
    }

    canEditChecklist = (userType: ChecklistUserType) => {
        return (userType !== CHECKLIST_SUPERVISOR_USER_TYPE && this.isOwner()) || this.isSupervisor();
    };

    addBudget = () => {
        this.budgets.push(new ProductBudget());
    }

    removeBudget = (budget: ProductBudget) => {
        this.budgets.remove(budget);
    }

    addDevelopment = () => {
        let dev = new BusinessLineDevelopment(this.sampleDescriptions, this.categories);
        this.orderedDevelopments.add(dev);
        this.selectedDevelopment(dev);
    }

    removeSelectedDevelopment = async () => {
        if (this.developments().length <= 1) {
            return;
        }
        let development = this.selectedDevelopment();
        if (!development) {
            return;
        }
        let index = this.developments().indexOf(development);

        await confirmDialog(i18n.t('Confirm')(), i18n.t('Remove {{name}} and all its data?', { name: development.getDesignName(index) })());
        this.developments.remove(development);
        this.selectedDevelopment(this.developments()[Math.max(0, index - 1)]);
    };

    selectDevelopment = (dev: BusinessLineDevelopment) => {
        this.selectedDevelopment(dev);
    }

    exportExcel(): void {
        businessLinesApi.exportDevelopment(this.id()).then((data) => {
            downloadBlob(data, this.name() + '.xls');
            this.excelExportError(false);
        }).catch(() => {
            this.excelExportError(true);
        });
    }

    isDevelopmentSelected(dev: BusinessLineDevelopment) {
        return dev === this.selectedDevelopment();
    }

    hasAnyFile(phase: number) {
        return this.files().some(file => file.phase === phase);
    }

    toData(): BusinessLineData {
        return {
            id: this.id(),

            name: this.name(),
            website: this.website(),
            public: this.public(),
            archived: this.archived(),
            country: this.country(),
            crops: this.crops().slice(),
            categories: this.categories().slice(),
            tags: this.tags().slice(),
            owner: this.owner(),
            editor: this.editor(),
            supervisor: this.supervisor(),
            viewers: this.viewers().slice(),
            dashboard_viewers: this.dashboardViewers().slice(),
            partners: this.partners().slice(),
            relevant_reason: this.relevantReason(),
            goal: this.goal(),
            hypothesis: this.hypothesis(),
            task_identification: this.taskIdentification(),
            targeted_market: this.targetedMarket(),
            market_size: this.marketSize(),
            potential_adoption: this.potentialAdoption() || null,
            potential_adoption_reason: this.potentialAdoptionReason(),
            promoting_equality: this.promotingEquality(),
            align_framework: this.alignFramework(),

            phase_1_changed_at: serializeDate(this.phase1ChangedAt()),
            phase_1_comment: this.phase1Comment(),

            sample_descriptions: this.sampleDescriptions().map((descr, idx) => descr.toData(idx)),
            test_subjects: this.testSubjects().map(ts => ts.toData()),
            sources_requirements: this.sourcesRequirements(),
            proposed_duration: this.proposedDuration() || null,
            budgets: this.budgets().map(budget => budget.toData()),
            potential_market: this.potentialMarket(),
            title_picture: this.titlePictureUpload.fileName,

            phase_2_changed_at: serializeDate(this.phase2ChangedAt()),
            phase_2_comment: this.phase2Comment(),

            developments: this.developments().map(dev => dev.toData()),

            fieldtrials_expansion: this.fieldtrialsExpansion(),
            fieldtrials_expansion_comment: this.fieldtrialsExpansionComment(),
            phase_4_potential_markets: this.phase4PotentialMarkets(),
            phase_4_potential_markets_comment: this.phase4PotentialMarketsComment(),
            benefits_estimation: this.benefitsEstimation(),
            suppliers: this.suppliers(),
            training: this.training(),
            infrastructure: this.infrastructure(),
            regulation: this.regulation(),

            phase_4_changed_at: serializeDate(this.phase4ChangedAt()),
            phase_4_comment: this.phase4Comment(),

            product_profile: this.productProfile(),
            external_investors: this.externalInvestors(),
            external_investors_comment: this.externalInvestorsComment(),
            next_evaluation_time_frame: this.nextEvaluationTimeFrame(),
            profile_doc: this.profileDoc(),
            profile_doc_user_file_name: this.profileDocUserFileName,

            phase_5_changed_at: serializeDate(this.phase5ChangedAt()),
            phase_5_comment: this.phase5Comment(),

            discard_changed_at: serializeDate(this.discardChangedAt()),
            discard_comment: this.discardComment(),

            checklist: checklistToData(this.checklist()),
            files: this.files().slice(),
            comments: this.comments().filter(comment => !!comment.comment().trim()).map(comment => comment.toData()),
            suggested_business_line: this.suggestedBusinessLineId ? { id: this.suggestedBusinessLineId } : null,

            deleted_public_messages: this.deletedPublicMessages.slice(),

            reduced_crop_loss: this.reducedCropLossChecked() ? this.reducedCropLoss() : '',
            reduced_postharvest_loss: this.reducedPostharvestLossChecked() ? this.reducedPostharvestLoss() : '',
            income_diversification: this.incomeDiversificationChecked() ? this.incomeDiversification() : '',
            use_resources_more_efficiently: this.useResourcesMoreEfficientlyChecked() ? this.useResourcesMoreEfficiently() : '',
            ecosystem_biodiversity: this.ecosystemBiodiversityChecked() ? this.ecosystemBiodiversity() : '',
            enhance_soil_health: this.enhanceSoilHealthChecked() ? this.enhanceSoilHealth() : '',
            reduced_ghg_emissions: this.reducedGhgEmissionsChecked() ? this.reducedGhgEmissions() : '',
            increase_profitability: this.increaseProfitabilityChecked() ? this.increaseProfitability() : '',

            reduced_crop_loss_result: this.reducedCropLossChecked() ? this.reducedCropLossResult() : '',
            reduced_postharvest_loss_result: this.reducedPostharvestLossChecked() ? this.reducedPostharvestLossResult() : '',
            income_diversification_result: this.incomeDiversificationChecked() ? this.incomeDiversificationResult() : '',
            use_resources_more_efficiently_result: this.useResourcesMoreEfficientlyChecked() ? this.useResourcesMoreEfficientlyResult() : '',
            ecosystem_biodiversity_result: this.ecosystemBiodiversityChecked() ? this.ecosystemBiodiversityResult() : '',
            enhance_soil_health_result: this.enhanceSoilHealthChecked() ? this.enhanceSoilHealthResult() : '',
            reduced_ghg_emissions_result: this.reducedGhgEmissionsChecked() ? this.reducedGhgEmissionsResult() : '',
            increase_profitability_result: this.increaseProfitabilityChecked() ? this.increaseProfitabilityResult() : '',
            external_id: this.externalID() ? this.externalID() : '',
        };
    }

    dispose() {
        this.developments().forEach(d => d.dispose());
    }

    addTestSubject = () => {
        this.orderedTestSubjects.add(new BusinessLineTestSubject());
    };

    canRemoveTestSubject = (ts: BusinessLineTestSubject): boolean => {
        return this.canEditImported() && !this.sampleDescriptions().some(descr => descr.testSubjectOrder() === ts.order().toString());
    };

    removeTestSubject = (ts: BusinessLineTestSubject) => {
        this.orderedTestSubjects.remove(ts);
    };

    addSampleDescription = () => {
        this.orderedSampleDescriptions.add(new BusinessLineSampleDescr(this.testSubjects));
    };

    removeSampleDescription = (descr: BusinessLineSampleDescr) => {
        confirmDialog(i18n.t('Remove sample?')(), i18n.t('Removing a sample will also remove the associated records.')()).then(() => {
            this.orderedSampleDescriptions.remove(descr);
        });
    };

    sortSampleDescrOnOrder = () => {
        this.sampleDescrSortingOption('order');
    };

    sortSampleDescrOnControl = () => {
        this.sampleDescrSortingOption('control');
    };

    sortSampleDescrOnLocation = () => {
        this.sampleDescrSortingOption('location');
    };

    removePublicMessage = (msg: BusinessLinePublicMessageData) => {
        this.publicMessages.remove(msg);
        this.deletedPublicMessages.push(msg.id);
    }
}

class ProductBudget {
    id = ko.observable<string | null>(null);
    detail = ko.observable('').extend({ required: true });
    unitPeriod = ko.observable('');
    number = ko.observable('').extend({ required: true, number: true, serverError: true });
    totalCost = ko.observable('').extend({ required: true, number: true, serverError: true });

    private errorGroup = ko.validation.group([this.detail, this.unitPeriod, this.number, this.totalCost], { deep: true });

    constructor(data?: ProductBudgetData) {
        if (data) {
            this.id(data.id);
            this.detail(data.detail);
            this.unitPeriod(data.unit_period);
            this.number(readDecimal(data.number));
            this.totalCost(readDecimal(data.total_cost));
        }
    }

    hasErrors(): boolean {
        return this.number.serverError?.() || this.totalCost.serverError?.() || (this.errorGroup as any).isAnyMessageShown()
    }

    toData(): ProductBudgetData {
        return {
            id: this.id(),
            detail: this.detail(),
            unit_period: this.unitPeriod(),
            number: this.number(),
            total_cost: this.totalCost()
        };
    }
}

class BusinessLineDevelopment {
    id = ko.observable<string | null>(null);
    order = ko.observable(0);
    changedAt = ko.observable<Date | null>(null);
    endDate = ko.observable<Date | null>(null);
    comment = ko.observable('');
    factors = ko.observableArray<ProductFactorDef>();
    orderedFactors: OrderedEntities<ProductFactorDef>;
    files = ko.observableArray<BusinessLineDevelopmentFileData>();
    records = ko.observableArray<BusinessLineDevelopmentRecord>();
    orderedRecords: OrderedEntities<BusinessLineDevelopmentRecord>;

    visibleFactors = ko.pureComputed(() => this.factors().filter(f => !!f.factorDef()));

    private subscription: KnockoutSubscription;

    constructor(private sampleDescriptions: ko.ObservableArray<BusinessLineSampleDescr>, private categories: ko.ObservableArray<ProjectCategoryData>, data?: BusinessLineDevelopmentData) {
        if (data) {
            this.id(data.id);
            this.order(data.order);
            this.changedAt(parseDate(data.changed_at));
            this.endDate(parseDate(data.end_date));
            this.comment(data.comment);
            this.factors(data.factors.map(factor => new ProductFactorDef(this.categories, factor)));
            this.files(data.files);
            this.updateDesign(data.records);
        } else {
            this.updateDesign([]);
        }
        this.subscription = this.sampleDescriptions.subscribe(this.onDesignChanged);

        this.orderedFactors = new OrderedEntities(this.factors);
        this.files.sort((a, b) => a.order - b.order);
        this.orderedRecords = new OrderedEntities(this.records);
    }

    dispose() {
        this.subscription.dispose();
        this.factors().forEach(f => f.dispose());
    }

    private onDesignChanged = () => {
        this.updateDesign([]);
    };

    private updateDesign(recordData: BusinessLineDevelopmentRecordData[]) {
        let recordsByOrder = toDict(this.records(), rec => [rec.order().toString(), rec]);

        let records = this.sampleDescriptions().map((descr) => {
            let record = recordsByOrder[descr.order().toString()];
            if (!record) {
                let data = descr.order() < recordData.length ? recordData[descr.order()] : { id: null, order: 0, control: false, disabled: false, show_in_summary: true };
                record = new BusinessLineDevelopmentRecord(this.visibleFactors, descr.order, descr.control, descr.controlLabel, data);
            }

            return record;
        });

        this.records(records);
    };

    canEditFactorType(productFactorDef: ProductFactorDef): boolean {
        return !this.records().some(rec => rec.hasValue(productFactorDef));
    }

    addFactor = () => {
        this.orderedFactors.add(new ProductFactorDef(this.categories));
    };

    removeFactor = (factor: ProductFactorDef) => {
        factor.dispose();
        this.orderedFactors.remove(factor);
    };

    toData(): BusinessLineDevelopmentData {
        return {
            id: this.id(),
            order: this.order(),
            changed_at: serializeDate(this.changedAt()),
            end_date: serializeDate(this.endDate()),
            comment: this.comment(),
            factors: this.factors().map(f => f.toData()),
            files: this.files().map((file, idx) => ({ order: idx, ...file })),
            records: this.records().map(rec => rec.toData())
        };
    }

    getDesignName(index: number): string {
        return i18n.t('Design')() + ' ' + (index + 1);
    }

    hasDesignError() {
        return this.factors().some(factor => factor.factorDef.isValid && !factor.factorDef.isValid());
    }

    getName(index: number): string {
        return i18n.t('Data')() + ' ' + (index + 1);
    }

    hasError() {
        return this.records().some(rec => rec.hasError());
    }
}

export class BusinessLineDevelopmentRecord {
    id = ko.observable<string | null>(null);
    disabled = ko.observable(false);
    showInSummary = ko.observable(false);
    private factorValues = new Map<ProductFactorDef, ProductFactorValue>();
    private initialData: FactorValuesDict | undefined = undefined;

    controlLabel = ko.pureComputed(() => this.control() ? i18n.t('Current Practice')() : i18n.t('Innovation')());

    constructor(public visibleFactors: ko.Computed<ProductFactorDef[]>, public order: ko.Observable<number>, public control: ko.PureComputed<boolean>, public innovationLabel: ko.PureComputed<string>, data?: BusinessLineDevelopmentRecordData) {
        if (data) {
            this.id(data.id);
            this.disabled(data.disabled);
            this.showInSummary(data.show_in_summary);
            this.initialData = data.factor_values;
        }
    }

    toData(): BusinessLineDevelopmentRecordData {
        return {
            id: this.id(),
            order: this.order(),
            control: this.control(),
            factor_values_write: this.visibleFactors().map(f => this.val(f).toData()),
            disabled: this.disabled(),
            show_in_summary: this.showInSummary(),
        };
    }

    hasValue(factor: ProductFactorDef): boolean {
        let value = this.val(factor).value();
        return value !== '' && value !== null && value !== undefined;
    }

    getFormattedValue(factor: ProductFactorDef): string {
        return this.val(factor).formatted();
    }

    val(factor: ProductFactorDef): ProductFactorValue {
        if (!this.factorValues.has(factor)) {
            this.factorValues.set(factor, new ProductFactorValue(factor, this.initialData));
        }
        return this.factorValues.get(factor)!;
    }

    hasError() {
        return this.visibleFactors().some(factor => {
            let val = this.val(factor);
            return val.value.isValid && !val.value.isValid();
        });
    }
}

class ProductFactorDef {
    id = ko.observable<string | null>(null);

    factorCategoryId = ko.observable('');
    factorDef = ko.observable<FactorDefData | null>().extend({
        required: true,
        validation: {
            validator: (val: FactorDefData) => {
                // might happen when user selects "Create" and then picks the wrong category,
                // or after the user changes the category in the first screen
                if (!val) {
                    return true;
                }
                for (let cat of this.categories()) {
                    if (cat.id === val.category.id) {
                        return true;
                    }
                }
                return false;
            },
            message: i18n.t('Invalid category')(),
        }
    });
    order = ko.observable<number>();
    benchmark = ko.observable('');
    marketTarget = ko.observable('');
    showInSummary = ko.observable(false);

    categoryOptions = ko.pureComputed(() => [{ id: '', name_json: asI18nText('Select')}].concat(this.categories()));
    factorDefSearch = getFactorDefSearch(this.factorDef, this.categories, this.factorCategoryId);

    hasHelpText = ko.pureComputed(() => !!translate(this.factorDef()?.help_text_json));
    helpTextVisible = ko.observable(false);

    private subscriptions: ko.Subscription[] = [];

    constructor(private categories: ko.ObservableArray<ProjectCategoryData>, data?: ProductFactorDefData) {
        if (data) {
            this.id(data.id);
            this.factorDef(data.factor_def);
            this.order(data.order);
            this.benchmark(data.benchmark);
            this.marketTarget(data.market_target);
            this.showInSummary(data.show_in_summary);

            this.onFactorChanged();
        }

        this.subscriptions = [
            this.factorCategoryId.subscribe(this.onFactorCategoryChanged),
            this.factorDef.subscribe(this.onFactorChanged)
        ];
    }

    dispose() {
        for (let sub of this.subscriptions) {
            sub.dispose();
        }
        this.subscriptions = [];
    }

    private onFactorCategoryChanged = (catId: string) => {
        let factorDef = this.factorDef();
        if (factorDef && catId && factorDef.category.id !== catId) {
            this.factorDef(null);
        }
    }

    private onFactorChanged = () => {
        let factorDef = this.factorDef();
        if (factorDef && !this.factorCategoryId()) {
            this.setCategoryId(factorDef.category.id!);
        }
    }

    private setCategoryId(id: string) {
        // ensure it's actually present in list
        for (let cat of this.categories()) {
            if (cat.id === id) {
                this.factorCategoryId(id);
                return;
            }
        }

        this.factorCategoryId('');
    }

    toData(): ProductFactorDefData {
        return {
            id: this.id(),
            factor_def: this.factorDef(),
            order: this.order(),
            benchmark: this.benchmark(),
            market_target: this.marketTarget(),
            show_in_summary: this.showInSummary()
        };
    }

    showHelpText = () => {
        this.helpTextVisible(true);
    };

    hideHelpText = () => {
        this.helpTextVisible(false);
    };
}

class ProductFactorValue implements CloudStorageUploadDelegate {
    value = ko.observable<string | Date | null>().extend({
        digit: {
            onlyIf: () => this.productFactorDef.factorDef()?.type === 'integer'
        },
        number: {
            onlyIf: () => this.productFactorDef.factorDef()?.type === 'decimal'
        }
    });

    valueUrl = ko.observable('');
    valueUserFileName = '';
    canReuseEndpoint = true;

    valueUpload = new CloudStorageUpload(this);

    fileUploadDelegate: FileUploadDelegate = {
        fileUploadError: this.valueUpload.fileUploadError,
        onFileContents: (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
            return this.valueUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
        }
    };

    constructor(private productFactorDef: ProductFactorDef, initialValues?: { [id: string]: FactorValue }) {
        let factorDef = productFactorDef.factorDef();
        let productFactorDefId = productFactorDef.id();

        if (factorDef && initialValues && productFactorDefId && initialValues[productFactorDefId] !== undefined && initialValues[productFactorDefId] !== null) {
            if (factorDef.type === 'document') {
                let value = initialValues[productFactorDefId] as FactorDocumentValue;
                this.value(value.id);
                this.valueUrl(value.url);
                this.valueUserFileName = value.user_file_name;
            } else if (factorDef.type === 'date') {
                this.value(parseDate(initialValues[productFactorDefId] as string));
            } else if (factorDef.type === 'choice') {
                this.value(initialValues[productFactorDefId]?.toString());
            } else {
                this.value(initialValues[productFactorDefId] as string);
            }
        }
    }

    choices = ko.pureComputed(() => {
        let choices = this.productFactorDef.factorDef()?.choices ?? [];
        if (!choices) {
            return [];
        }

        let res = choices.map(choice => ({ id: choice.id, name: translate(choice.name_json) }));
        res.sort((a, b) => a.name.localeCompare(b.name));
        res.splice(0, 0, { id: '', name: i18n.t('Select')() });

        return res;
    });

    getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
        return getProductDocUploadEndpoint(contentType);
    }

    onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
        this.value(fileName);
        this.valueUrl(publicURL);
        this.valueUserFileName = userFileName;
    }

    toData(): FactorValue {
        let type = this.productFactorDef.factorDef()?.type;
        if (type === 'document' && this.value()) {
            return {
                id: this.value() as string,
                url: this.valueUrl(),
                user_file_name: this.valueUserFileName
            };
        } else if (type === 'date') {
            return serializeDate(this.value() as Date);
        } else if (type === 'choice' && !this.value()) {
            return undefined;
        } else {
            return this.value() as string;
        }
    }

    formatted(): string {
        let type = this.productFactorDef.factorDef()?.type;
        let value = this.value();
        if (value === undefined || value === null) {
            return '';
        }
        if (type === 'document' && value) {
            return this.valueUserFileName;
        }
        if (type === 'date') {
            return tryFormatDate(value) ?? '';
        }
        if (type === 'choice') {
            let choice = findById(this.choices(), value.toString());
            return choice ? choice.name : '';
        }

        return value.toString();
    }
}

class BusinessLineTestSubject {
    id: string | null = null;
    order = ko.observable<number>();
    name = ko.observable('').extend({ required: true });
    description = ko.observable('').extend({ required: true });
    control = ko.observable(false)

    private errorGroup = ko.validation.group([this.description], { deep: true });

    constructor(data?: BusinessLineTestSubjectData) {
        if (data) {
            this.id = data.id;
            this.order(data.order);
            this.name(data.name);
            this.description(data.description);
            this.control(data.control);
        }
    }

    toData(): BusinessLineTestSubjectData {
        return {
            id: this.id,
            order: this.order(),
            name: this.name(),
            description: this.description(),
            control: this.control(),
        };
    }

    hasErrors(): boolean {
        return (this.errorGroup as any).isAnyMessageShown();
    }
}

export class BusinessLineSampleDescr {
    id: string | null = null;
    order = ko.observable<number>();
    testSubjectOrder = ko.observable<string>(null).extend({required: true});
    site = ko.observable<SiteData>(null);
    siteName = ko.pureComputed(() => translate(this.site()?.name_json) || i18n.t('Unknown')());
    comment = ko.observable('');
    size = ko.observable('').extend({ serverError: true });
    unit = ko.observable('').extend({ serverError: true });

    testSubjectOptions = ko.pureComputed(() => {
        const controlText = i18n.t("Control")();
        const testSubjects = this.testSubjects().map((ts) => ({
          name: `#${ts.order() + 1}${ts.control() ? " (" + controlText + ")" : ""}`,
          value: ts.order().toString(),
        }));
        // @ts-ignore
        return [{name: i18n.t("Select")(), value: null}, ...testSubjects]
      });


    control = ko.pureComputed(() => this.testSubjects()[this.getTestSubjectOrder()]?.control() || false)
    controlLabel = ko.pureComputed<string>(() => {
        const ts_order = this.getTestSubjectOrder()
        const controlLabel = `(${i18n.t('Control')()})`
        const tsName = this.testSubjects()[ts_order]?.name();
        if (tsName) {
            return `${tsName} #${ts_order + 1} ${this.control() ? controlLabel : ''}`;
        }
        return i18n.t('Not selected')()
    });

    getTestSubjectOrder() {
        return parseInt(this.testSubjectOrder(), 10);
    }

    siteSearch = getSiteSearch(this.site);

    constructor(private testSubjects: KnockoutObservableArray<BusinessLineTestSubject>, data?: BusinessLineSampleDescrData) {
        if (data) {
            this.id = data.id;
            this.order(data.order);
            this.testSubjectOrder(data.test_subject_order?.toString() || null);
            this.site(data.site);
            this.comment(data.comment);
            this.size(data.size);
            this.unit(data.unit);
        }
    }

    toData(order: number): BusinessLineSampleDescrData {
        return {
            id: this.id,
            order: order,
            test_subject_order: this.getTestSubjectOrder(),
            site: this.site(),
            comment: this.comment(),
            size: this.size(),
            unit: this.unit()
        }
    }
}

let nextClientId = 0;

export class BusinessLineComment {
    clientId = ++nextClientId;

    id: string | null = null;
    comment = ko.observable('');
    userId: string;
    userName: string;
    createdAt: Date;

    canEdit = ko.pureComputed(() => {
        return this.currentUser.id === this.userId
            || (this.owner() && this.currentUser.id === this.owner().id)
            || (this.editor() && this.currentUser.id === this.editor().id)
            || this.currentUser.role === 'admin';
    });

    constructor(private currentUser: UserData, private owner: ko.Observable<UserData>, private editor: ko.Observable<UserData>, public phase: number, public step: string, data?: BusinessLineCommentData) {
        if (data) {
            this.id = data.id;
            this.comment(data.comment);
            this.userId = data.user_id;
            this.userName = data.user_name;
            this.createdAt = parseDateTime(data.created_at);
        } else {
            this.userId = currentUser.id;
            this.userName = currentUser.name;
            this.createdAt = new Date();
        }
    }

    commentHeader = ko.pureComputed<string>(() => {
        return this.userName + ' - ' + tryFormatDateTime(this.createdAt);
    });

    toData(): BusinessLineCommentData {
        return {
            id: this.id,
            phase: this.phase,
            step: this.step,
            comment: this.comment(),
            client_id: this.clientId
        };
    }
}
