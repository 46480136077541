import * as ko from 'knockout';

import { ListRequestParams, RemoveResult } from '@core/api/request';
import { ListLoaderDelegate, ListLoader } from '@core/components/list_loader';
import { cropsApi, CropData } from '../api/simple_api';
import { ListHeaderAction } from '@core/components/list_header';
import i18n from '@core/i18n';
import { canEditCrops } from 'permissions';

let template = require('../../templates/crops.html').default;

class CropsScreen implements ListLoaderDelegate<CropData> {
    private loader: ListLoader<CropData, CropData>;
    private importing = ko.observable(false);

    importFromFieldtrials = () => {
        this.importing(true);

        cropsApi.importFromFieldtrials().then(() => {
            this.importing(false);
            this.loader.refresh();
        }).catch(() => {
            this.importing(false);
        });
    }

    listActions: ListHeaderAction[] = canEditCrops() ? [
        { title: i18n.t('Import from field trials')(), icon: 'file_download', onClick: this.importFromFieldtrials, loading: this.importing }
    ] : [];

    onReady(loader: ListLoader<CropData, CropData>) {
        this.loader = loader;
    }

    fetch(params: ListRequestParams) {
        return cropsApi.list(params);
    }

    instantiate(data: CropData) {
        return data;
    }

    remove(id: string): Promise<RemoveResult> {
        return null;
    }

    canRemove(data: CropData) {
        return false;
    }
}

export let crops = { name: 'crops', viewModel: CropsScreen, template: template };

ko.components.register(crops.name, crops);
