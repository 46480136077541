import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';

let template = require('../../templates/components/confirm_dialog.html').default;

class ConfirmDialog {
    title: string;
    text: string;
    private result: Deferred<{} | null>;

    constructor(params: { title: string, text: string, result: Deferred<{} | null> }) {
        this.title = params.title;
        this.text = params.text;
        this.result = params.result;
    }

    onConfirm = () => {
        this.result.resolve(null);
    }

    onCancel = () => {
        this.result.reject();
    }
}

ko.components.register('confirm-dialog', { viewModel: createWithComponent(ConfirmDialog), template: template });

export function confirmDialog(title: string, text: string): Promise<{}> {
    return app.formsStackController.push({
        title: title,
        name: 'confirm-dialog',
        params: {
            title: title,
            text: text,
            result: new Deferred<{}>()
        }
    });
}
