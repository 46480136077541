import * as ko from 'knockout';
import { Deferred } from '@core/utils/deferred';
import { SuggestedBusinessLineParams, BusinessLineFilters} from 'api/simple_api';
import { downloadBlob } from '@core/utils';

const template =
  require("../../templates/components/excel_exporter.html").default;

type Filters = SuggestedBusinessLineParams | BusinessLineFilters;
type ExportFunction = (filters: Filters) => Promise<Blob>;

class ExcelExporter {
    constructor(params : { filters: Filters, result: Deferred<{}>, exportExcel: ExportFunction, fileName: string }) {
        this.download(
            params.filters,
            params.result,
            params.exportExcel,
            params.fileName
        );
    }

    private async download(filters: Filters, result: Deferred<{}>, exportExcel: ExportFunction, fileName: string) {
        try {
            const data = await exportExcel(filters);
            downloadBlob(data, fileName);
        } finally {
            result.resolve(null);
        }
    }
}

export let excelExporter = {
    name: 'excel-exporter',
    viewModel: ExcelExporter,
    template: template,
};

ko.components.register(
    excelExporter.name,
    excelExporter
);