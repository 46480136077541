import * as ko from 'knockout';
import { MaybeKO, asObservable, createWithComponent } from '../utils/ko_utils';

let template = require('../../templates/components/list_header.html').default;

export interface ListHeaderAction {
    title: string;
    icon: string;
    iconClass?: string;
    href?: string;
    hrefTarget?: string;
    onClick?: () => void;
    loading?: ko.Observable<boolean>;
}

class ListHeader {
    actions: ko.Observable<ListHeaderAction[]>;

    constructor(params: { actions: MaybeKO<ListHeaderAction[]> }) {
        this.actions = asObservable(params.actions);
    }

    onClick = (action: ListHeaderAction) => {
        if (action.loading && action.loading()) {
            return;
        }

        if (action.onClick) {
            action.onClick();
        }
    }
}

ko.components.register('list-header', { viewModel: createWithComponent(ListHeader), template: template });
