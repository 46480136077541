import page from 'page';
import * as ko from 'knockout';
import i18n from '../i18n';

import { ListRequestParams, RemoveResult } from '../api/request';
import { FormSelectSearchConfiguration } from './form_select_search';
import { Action } from '../components/basic_widgets';
import * as utils from '../utils';
import { I18nText, translate } from '../i18n_text';
import { MaybeKO, createWithComponent } from '../utils/ko_utils';
import { FilterDelegate, getFilterObservable } from './list_filters';
import { removeDialog } from './remove_dialog';

let template = require('../../templates/components/list_loader.html').default;

type FilterValue = string | Date;

export interface ListFilter {
    name: string;
    slug: string;
    type: 'text' | 'numeric' | 'date' | 'select' | 'choices';

    config?: FormSelectSearchConfiguration<{ id?: string | ko.Observable<string> }>;
    choices?: { name: string, value: string }[];

    value?: ko.Observable<FilterValue>;
    minValue?: ko.Observable<FilterValue>;
    maxValue?: ko.Observable<FilterValue>;
}

export function makeYesNoFilter(title: string, obs: ko.Observable<'yes' | 'no' | 'all'>): ListFilter {
    return {
        name: title,
        slug: '',
        type: 'choices',
        choices: [
            { name: i18n.t('All')(), value: 'all' },
            { name: i18n.t('Yes')(), value: 'yes' },
            { name: i18n.t('No')(), value: 'no' },
        ],
        value: obs
    };
}

export interface ListLoaderDelegate<TData, T = TData> {
    fetch(params: ListRequestParams): Promise<TData[]>;
    instantiate(data: TData): T;
    getEditUrl?(entity: T): string;
    remove(id: string): Promise<RemoveResult>;
    canRemove(entity: T): boolean;
    archive?(id: string): Promise<any>;
    canArchive?(entity: T): boolean;
    includesArchived?(): boolean;

    onReady?(loader: ListLoader<TData, T>): void;
    getName?(entity: T): string;
    getActions?(entity: T): Action[];
    sortBys?: ko.ObservableArray<ko.Observable<string>>;
    sortingOptions?: { name: string, value: string }[];
    allowEmptySortBy?: boolean;
    filters?: ListFilter[];
    newFilters?: FilterDelegate[];
    noItemsText?: string;
}

const PAGE_SIZE = 100;

export class ListLoader<TData, T extends { id?: MaybeKO<string>, name_json?: I18nText, nameJson?: ko.Observable<I18nText>, name?: MaybeKO<string> }> {
    loading = ko.observable(true);
    showRetry = ko.observable(false);
    items = ko.observableArray<T>();
    hasMore = ko.observable(false);
    focusedFilter = ko.observable<ListFilter | null>(null);

    private delegate: ListLoaderDelegate<TData, T>
    private lastRequestId = 0;
    private subscriptions: ko.Subscription[] = [];
    private sortBySubscriptions: ko.Subscription[] = [];

    constructor(params: { delegate: ListLoaderDelegate<TData, T> }) {
        this.delegate = params.delegate;

        if (this.delegate.sortBys) {
            this.subscribeSortBys();
            this.subscriptions.push(this.delegate.sortBys.subscribe(this.onSortBysChanged));
        }
        if (this.delegate.filters) {
            for (let filter of this.delegate.filters) {
                let refresh = filter.type === 'select' || filter.type === 'choices' ? this.refreshAndBlur : this.refresh;

                if (filter.config && filter.config.entity) {
                    this.subscriptions.push(filter.config.entity.subscribe(refresh));
                }
                if (filter.value) {
                    this.subscriptions.push(filter.value.subscribe(refresh));
                }
                if (filter.minValue) {
                    this.subscriptions.push(filter.minValue.subscribe(refresh));
                }
                if (filter.maxValue) {
                    this.subscriptions.push(filter.maxValue.subscribe(refresh));
                }
            }
        }
        if (this.delegate.newFilters) {
            for (let filter of this.delegate.newFilters) {
                this.subscriptions.push(getFilterObservable(filter).subscribe(this.refresh));
            }
        }

        if (this.delegate.onReady) {
            this.delegate.onReady(this);
        }
        this.loadMore();
    }

    isFilterDefined = ko.pureComputed(() => {
        for (let filter of this.delegate.filters || []) {
            if (this.isFilterSet(filter)) {
                return true;
            }
        }

        for (let filter of this.delegate.newFilters || []) {
            if (getFilterObservable(filter)()) {
                return true;
            }
        }

        return false;
    });

    noItemsText = ko.pureComputed<string>(() => {
        return this.delegate.noItemsText || i18n.t('No items defined.')();
    });

    dispose() {
        for (let subscription of this.subscriptions) {
            subscription.dispose();
        }
        this.disposeSortBys();
    }

    private disposeSortBys() {
        for (let subscription of this.sortBySubscriptions) {
            subscription.dispose();
        }
        this.sortBySubscriptions = [];
    }

    private subscribeSortBys() {
        this.disposeSortBys();
        if (this.delegate.sortBys) {
            for (let sortBy of this.delegate.sortBys()) {
                this.sortBySubscriptions.push(sortBy.subscribe(this.refresh));
            }
        }
    }

    private onSortBysChanged = () => {
        this.subscribeSortBys();
        this.refresh();
    }

    refresh = () => {
        this.items([]);
        this.hasMore(false);
        this.loadMore();
    }

    private refreshAndBlur = () => {
        this.refresh();
        this.hideFilter();
    }

    loadMore = () => {
        let requestId = ++this.lastRequestId;
        let items = this.items();

        this.showRetry(false);
        this.loading(true);
        this.delegate.fetch({ limit: PAGE_SIZE + 1, offset: items.length }).then((itemsData) => {
            if (requestId !== this.lastRequestId) {
                return;
            }

            let newItems = itemsData.map((itemData: TData) => this.delegate.instantiate(itemData));

            if (newItems.length > PAGE_SIZE) {
                newItems.pop();
                this.hasMore(true);
            } else {
                this.hasMore(false);
            }

            this.items(items.concat(newItems));
        }).then(() => {
            this.loading(false);
        }).catch(e => {
            this.showRetry(items.length === 0);
            this.loading(false);
        });
    }

    confirmRemove = (item: T) => {
        let name: string | I18nText | undefined = this.delegate.getName && this.delegate.getName(item);
        name = name || translate(item.name_json);
        name = name || (item.nameJson && translate(item.nameJson()));
        name = name || (item.name && ko.unwrap(item.name));

        const itemId = item.id;
        if (!itemId || !name) {
            return;
        }

        removeDialog(name, () => this.delegate.remove(ko.unwrap(itemId))).then(() => {
            this.items.remove(item);
        });
    }

    getActions = (entity: T): Action[] => {
        let actions: Action[] = [];

        if (this.delegate.getActions) {
            actions = this.delegate.getActions(entity);
        }

        const editUrl = this.delegate.getEditUrl && this.delegate.getEditUrl(entity);
        if (editUrl) {
            actions.push({
                icon: 'edit',
                title: i18n.t('Edit')(),
                cssClass: '',
                onClick: () => { page(editUrl); }
            });
        }

        if(this.delegate.canArchive && this.delegate.canArchive(entity)) {
            actions.push({
                icon: 'archive_outline',
                title: i18n.t('Archive')(),
                cssClass: '',
                onClick: () => {
                    this.delegate.archive(ko.unwrap(entity.id)).then(
                        () => {
                            if(!this.delegate.includesArchived()) {
                                this.items.remove(entity);
                            }
                        }
                    );
                }
            })
        }

        if (this.delegate.canRemove(entity)) {
            actions.push(            {
                icon: 'delete',
                title: i18n.t('Remove')(),
                cssClass: 'remove',
                onClick: () => { this.confirmRemove(entity); }
            });
        }

        return actions;
    }

    formatFilterValue = (filter: ListFilter) => {
        let any = i18n.t('any')();
        let notSet = i18n.t('Not set')();

        if (filter.config && filter.config.entity) {
            if (filter.config.entity()) {
                return filter.config.getSummaryName(filter.config.entity());
            } else {
                return notSet;
            }
        }

        if (filter.value) {
            let value = filter.value();

            if (value) {
                if (filter.choices) {
                    for (let choice of filter.choices) {
                        if (choice.value === value) {
                            return choice.name;
                        }
                    }
                }

                return utils.tryFormatDate(value);
            } else {
                return notSet;
            }
        }

        let min: string | undefined;
        let max: string | undefined;
        let hasMinOrMax = false;
        if (filter.minValue && filter.minValue()) {
            min = utils.tryFormatDate(<string | Date>filter.minValue());
            hasMinOrMax = true;
        } else {
            min = any;
        }
        if (filter.maxValue && filter.maxValue()) {
            max = utils.tryFormatDate(<string | Date>filter.maxValue());
            hasMinOrMax = true;
        } else {
            max = any;
        }

        return hasMinOrMax ? min + ' - ' + max : notSet;
    }

    isFilterSet = (filter: ListFilter) => {
        return (filter.config && filter.config.entity && filter.config.entity())
            || (filter.value && filter.value())
            || (filter.minValue && filter.minValue())
            || (filter.maxValue && filter.maxValue());
    }

    showFilter = (filter: ListFilter, event: Event) => {
        event.stopPropagation();

        this.focusedFilter(filter);

        if (filter.type !== 'date') {
            setTimeout(() => {
                if (event.target) {
                    $(event.target).parent().next().find('input').first().focus();
                }
            }, 0);
        }
    }

    keepFilter = (_: {}, event: Event) => {
        event.stopPropagation();
    }

    hideFilter = () => {
        this.focusedFilter(null);

        return true;
    }
}

ko.components.register('list-loader', { viewModel: createWithComponent(ListLoader as any), template: template });
