import * as ko from 'knockout';

import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { SuggestedBusinessLineData, suggestedBusinessLinesApi } from '../api/simple_api';
import { SuggestedBusinessLine } from '../models/suggested_business_line';

let template = require('../../templates/suggested_business_line_edit.html').default;

export class SuggestedBusinessLineEditScreen extends BaseEditScreen<SuggestedBusinessLineData, SuggestedBusinessLine> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<SuggestedBusinessLineData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: suggestedBusinessLinesApi, focus: true }, params, componentInfo);
    }

    instantiate(data: SuggestedBusinessLineData) {
        return new SuggestedBusinessLine(data);
    }
}

export let suggestedBusinessLineEdit = { name: 'suggested-business-line-edit', viewModel: createWithComponent(SuggestedBusinessLineEditScreen), template: template };

ko.components.register(suggestedBusinessLineEdit.name, suggestedBusinessLineEdit);

