import i18n from './i18n';

// source: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export const LANGUAGES = [
    ['en', 'English', 'English'],
    ['fr', 'Français', 'French'],
    ['es', 'Español', 'Spanish'],
    ['de', 'Deutsch', 'German'],
    ['bn', 'বাংলা', 'Bengali, Bangla'],
    ['id', 'Bahasa Indonesia', 'Indonesian']
];

export interface I18nText {
    default: string;
    [language: string] : string;
}

/**
 * Tries to return the value in the user prefered language.
 * If it's null or empty, returns the value in the default language
 */
export function translate(text: I18nText | undefined): string {
    if (!text) {
        return '';
    }

    return text[i18n.userInputLanguage()] || text[text['default']] || '';
}

/**
 * Returns the default translation from i18n text
 */
export function getDefaultTranslation(text: I18nText | undefined): string {
  if (!text) {
    return '';
  }
  return text[text['default']] || '';
}

export function asI18nText(value: string): I18nText {
    let text: I18nText = {
        default: i18n.userInputLanguage(),
    };
    text[i18n.userInputLanguage()] = value;

    return text;
}

export function copyI18nText(text: I18nText): I18nText {
    return ko.utils.extend({}, text) as I18nText;
}

export function isI18nTextEmpty(text: I18nText): boolean {
    if (!text) {
        return true;
    }

    for (let key in text) {
        if (text.hasOwnProperty(key) && text[key]) {
            return false;
        }
    }

    return true;
}
