import * as ko from "knockout";
import i18n from "../i18n";

export interface Point {
  lat: number;
  lng: number;
}

declare const google: any;

export interface GeoJSON {
  type: "Feature";
  geometry: {
    type: "Point" | "Polygon";
    coordinates: number[] | number[][][];
  };
  properties: {
    area?: number;
    title?: string;
    info?: { title: string; value: string }[];
    value: any;
    id: string | number;
  };
}

let currentOverlays: any[] = [];
let map: any;
let popup: any;

export function fitMap(coords: Point[]) {
  if (!map) {
    return;
  }

  let bounds = new google.maps.LatLngBounds();
  for (let coord of coords) {
    bounds.extend(coord);
  }
  map.setCenter(bounds.getCenter());
  map.fitBounds(bounds);
}

function addShape(
  path: any | Point[],
  options: {
    clickable: boolean;
    polyline?: boolean;
    onEdit?: (coords: Point[]) => void;
  }
): void {
  let shape = new (
    options.polyline ? google.maps.Polyline : google.maps.Polygon
  )({
    path,
    clickable: options.clickable,
    editable: !!options.onEdit,
    geodesic: true,
    strokeColor: "#FFFFFF",
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: "#FFFFFF",
    fillOpacity: 0.3,
  });
  shape.setMap(map);
  currentOverlays.push(shape);

  if (options.onEdit) {
    let path = shape.getPath();
    let callEdit = () => options.onEdit(path.getArray().map(toPoint));

    google.maps.event.addListener(path, "set_at", callEdit);
    google.maps.event.addListener(path, "remove_at", callEdit);
    google.maps.event.addListener(path, "insert_at", callEdit);
  }
}

export function resetOverlays() {
  for (let overlay of currentOverlays) {
    google.maps.event.clearInstanceListeners(overlay);
    overlay.setMap(null);
  }
  currentOverlays = [];
}

export function addMarkers(markers: any[]) {
  for (let marker of markers) {
    currentOverlays.push(marker);
  }
}

export function initMap(element: Element): any {
  let mapElem = document.getElementById("map");
  ko.cleanNode(mapElem);
  element.appendChild(mapElem);

  if (!map) {
    map = new google.maps.Map(mapElem, {
      mapTypeId: "hybrid",
      tilt: 0, // a 45 deg tilt shows polylines in the wrong place
      zoom: 18,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    });

    popup = new google.maps.InfoWindow({
      content: "",
      pixelOffset: 0,
      maxWidth: 300,
    });
  } else {
    map.setOptions({ draggableCursor: "pointer" });
    map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
  }

  ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
    document.getElementById("map-container").appendChild(mapElem);
  });

  return map;
}

function toPoint(pt: any): Point {
  return { lat: pt.lat(), lng: pt.lng() };
}

interface Params {
  points: GeoJSON[];
  obs: ko.Observable<any>;
}

ko.bindingHandlers["mapSelect"] = {
  init: (element: Element, valueAccessor: () => Params) => {
    initMap(element);
  },

  update: (element: Element, valueAccessor: () => Params) => {
    resetOverlays();
    let params = valueAccessor();

    let features = ko.unwrap(params.points);

    if (!features || features.length === 0) {
      return;
    }

    let markers: any[] = [];
    let allCoords: { lat: number; lng: number }[] = [];
    for (let feature of features) {
      let marker: any;
      if (feature.geometry.type === "Point") {
        let [lat, lng] = feature.geometry.coordinates as number[];
        let position = { lat, lng };
        allCoords.push(position);
        marker = new google.maps.Marker({ position });
      } else {
        let coords = (feature.geometry.coordinates[0] as number[][]).map(
          ([lng, lat]) => ({ lat, lng })
        );
        allCoords = allCoords.concat(coords);

        addShape(coords, { clickable: false });

        let polygonBounds = new google.maps.LatLngBounds();
        for (let coord of coords) {
          polygonBounds.extend(coord);
        }
        marker = new google.maps.Marker({
          position: polygonBounds.getCenter(),
        });
      }
      marker.setMap(map);

      let props = feature.properties;
      if (props) {
        let content = `
          <div class="map-popup-title">${props.title} ${
          params.obs()?.id === feature.properties.id
            ? `(${i18n.t("selected")()})`
            : ""
        }</div>`;

        google.maps.event.addListener(marker, "mouseover", () => {
          popup.setContent(content);
          popup.open(map, marker);
        });

        google.maps.event.addListener(marker, "click", () => {
          params.obs(feature.properties.value);
        });
      }
      markers.push(marker);
    }
    addMarkers(markers);
    if (params.obs) {
      const lat = params.obs()?.location_lat;
      const lng = params.obs()?.location_lon;
      if (lat && lng) {
        fitMap([{ lat, lng }]);
      } else {
        fitMap(allCoords);
      }
    } else {
      fitMap(allCoords);
    }
  },
};
