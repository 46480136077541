import * as ko from 'knockout';
import page from 'page';

import { DashboardSettingsData, dashboardSettings, updateDashboardSettings } from '../api/simple_api';
import { createWithComponent } from '@core/utils/ko_utils';
import { DashboardSettings } from 'models/dashboard_settings';
import { BaseForm } from '@core/screens/base_form';

let template = require('../../templates/dashboard_settings_edit.html').default;


class DashboardSettingsEditScreen extends BaseForm<DashboardSettingsData & { id: string }> {
    entity = ko.validatedObservable<DashboardSettings>(undefined);

    constructor(params: {}, componentInfo: ko.components.ComponentInfo) {
        super({});

        let promise = dashboardSettings().then(data => {
            this.entity(new DashboardSettings(data));
        });
        this.loadedAfter(promise).then(() => { this.focusFirst(componentInfo.element) });
    }

    save = async () => {
        if (this.validateLocal(this.entity)) {
            let data = this.entity().toData();
            let validation = await this.executeSaveRequest(updateDashboardSettings(data));

            this.saving(false);
            if (validation.isValid) {
                page('/dashboard/');
            } else {
                this.applyModelServerErrors(this.entity, validation.errors);
            }
        }
    }

    cancel = () => {
        page('/dashboard/');
    };
}


export let dashoardSettingsEdit = { name: 'dashboard-settings-edit', viewModel: createWithComponent(DashboardSettingsEditScreen), template: template };

ko.components.register(dashoardSettingsEdit.name, dashoardSettingsEdit);
