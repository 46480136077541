import * as ko from 'knockout';
import { MaybeKO } from '../utils/ko_utils';
import { MONTH_OPTIONS } from '../utils';

ko.bindingHandlers['formattedMonth'] = {
    update: (element: Element, valueAccessor: () => MaybeKO<number>) => {
        let month = ko.unwrap(valueAccessor());
        let monthOpt = MONTH_OPTIONS.filter(opt => opt.value === month)[0];
        element.textContent = monthOpt ? monthOpt.name : '—';
    }
};
