import * as ko from 'knockout';
import page from 'page';
import { ProjectCategoryData, projectCategoriesApi, businessLinesApi, DashboardData, countriesApi, CountryData, PartnerData, partnersApi, DashboardFilters } from 'api/simple_api';
import { FilterDelegate } from '@core/components/list_filters';
import i18n from '@core/i18n';
import { asArray, deflateList, updateLocationWithQueryString } from '@core/utils';

let template = require('../../templates/dashboard.html').default;

interface FilterParams {
    category_ids: string | string[];
    country_ids: string | string[];
    partner_ids: string | string[];
}
class DashboardScreen {
    loading = ko.observable(true);

    currentCategory = ko.observable<ProjectCategoryData>(null);
    categories = ko.observableArray<ProjectCategoryData>();

    private countryFilters = ko.observableArray<CountryData>();
    private categoryFilters = ko.observableArray<ProjectCategoryData>();
    private partnerFilters = ko.observableArray<PartnerData>();

    filters: FilterDelegate[] = [
        { list: countriesApi.list.bind(countriesApi), entities: this.countryFilters, title: i18n.t('Countries')() },
        { list: projectCategoriesApi.list.bind(projectCategoriesApi), entities: this.categoryFilters, title: i18n.t('Categories')() },
        { list: partnersApi.list.bind(partnersApi), entities: this.partnerFilters, title: i18n.t('Partners')() },
    ];

    data = ko.observable<DashboardData>({
        title_line_1: '',
        title_line_2: '',
        subtitle: '',
        description: '',
        innovations: [],
        locations: [],
        categories_chart: { use_category_colors: false, quarters: [], groups: [] },
        phases_chart: { use_category_colors: false, quarters: [], groups: [] },
        team: []
    });

    private disposed = false;
    private subscriptions: KnockoutSubscription[] = [];
    private carouselToken: number;

    constructor(params: { filters: FilterParams }) {       
        let categoryIds = asArray(params.filters.category_ids);
        let countryIds = asArray(params.filters.country_ids);
        let partnerIds = asArray(params.filters.partner_ids);
        
        let categoriesPromise = projectCategoriesApi.list({});
        let countriesPromise = countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
        let partnersPromise = partnerIds.length > 0 ? partnersApi.list({ ids: partnerIds }) : Promise.resolve<PartnerData[]>([]);

        Promise.all([categoriesPromise, countriesPromise, partnersPromise]).then(async ([categories, countries, partners]) => {
            this.categoryFilters(categories.filter(cat => categoryIds.indexOf(cat.id) >= 0));
            this.countryFilters(countries);
            //@ts-ignore
            this.partnerFilters(partners);
            
            this.categories(categories);
            this.currentCategory(categories[0]);

            if(this.disposed) {
                return;
            }
    
            this.subscriptions = [
                this.countryFilters.subscribe(this.refresh),
                this.categoryFilters.subscribe(this.refresh),
                this.partnerFilters.subscribe(this.refresh),
            ];
            
            //@ts-ignore
            this.carouselToken = setInterval(this.changeCarousel, 5000);
    
            await this.refresh();
        });
    }

    dispose() {
        this.disposed = true;
        if (this.carouselToken) {
            clearInterval(this.carouselToken);
            this.carouselToken = undefined;
        }
        this.subscriptions.forEach(sub => sub.dispose());
    }

    private refresh = async () => {
        try {
            const filters: DashboardFilters = {
                category_ids: deflateList(this.categoryFilters),
                country_ids: deflateList(this.countryFilters),
                partner_ids: deflateList(this.partnerFilters),
                tag_ids: [],
                years: [],
            };
            updateLocationWithQueryString(filters);
            this.data(await businessLinesApi.dashboard(filters));
        } finally {
            this.loading(false);
        }
    }

    private changeCarousel = () => {
        let next = (this.categories().indexOf(this.currentCategory()) + 1) % this.categories().length;
        this.currentCategory(this.categories()[next]);
    };

    transform = ko.pureComputed(() => {
        let idx = this.categories().indexOf(this.currentCategory());
        return `translateX(-${idx * 100}%)`;
    });

    selectCarouselCategory = (category: ProjectCategoryData) => {
        this.currentCategory(category);
    };

    selectCategory = (category: ProjectCategoryData) => {
        page('/dashboard_details/?category_ids=' + category.id);
    };
}

export let dashboard = { name: 'dashboard', viewModel: DashboardScreen, template: template };

ko.components.register(dashboard.name, dashboard);
