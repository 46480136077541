import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { UserTagData, userTagsApi } from '../api/simple_api';

let template = require('../../templates/user_tags.html').default;

class UserTagsScreen implements ListLoaderDelegate<UserTagData> {
    fetch(params: ListRequestParams) {
        return userTagsApi.list(params);
    }

    instantiate(data: UserTagData) {
        return data;
    }

    getEditUrl(entity: UserTagData): string {
        return '/user_tags/' + entity.id + '/';
    }

    remove(id: string) {
        return userTagsApi.remove(id);
    }

    canRemove(data: UserTagData) {
        return true;
    }
}

export let userTags = { name: 'user-tags', viewModel: UserTagsScreen, template: template };

ko.components.register(userTags.name, userTags);
