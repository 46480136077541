import * as ko from 'knockout';
import i18n from '../i18n';

import { MaybeKO, asObservable, unwrap } from '../utils/ko_utils';
import { app } from '../app';
import { ListRequestParams } from '../api/request';
import { Deferred } from '../utils/deferred';
import { I18nText, translate } from '../i18n_text';

let formSelectSearchTemplate = require('../../templates/components/form_select_search.html').default;

interface IdData {
    id?: string | null | ko.Observable<string>;
}

export interface FormSelectCreate<TData, T> {
    title: string;
    componentName: string;
    extraParams?: {};
    instantiate?: (data: TData) => T;
    insert?: (value: T) => void;
}

export interface FormSelectSearchConfiguration<T extends IdData> {
    list: (params: ListRequestParams) => Promise<T[]>;

    manuallyManageEntities?: boolean;
    entities?: ko.ObservableArray<T>;
    entity?: ko.Observable<T | null>;

    title?: MaybeKO<string>;
    getSummaryName(entity: T): string | I18nText;

    create?: FormSelectCreate<IdData, T>;

    confirmChangeEntities?: () => Promise<{}>;
    confirmEditEntity?: () => Promise<{}>;

    advancedSearch?: {
        componentName: string,
        extraParams?: {},
        instantiate: (data: IdData) => T,
        serialize: (instance: T) => IdData
    };
}

interface FormSelectSearchInputParams<T extends IdData> {
    config: MaybeKO<FormSelectSearchConfiguration<T>>;
    enable?: ko.Observable<boolean>;
    icon?: MaybeKO<string>;
}

class FormSelectSearchInput<T extends IdData> {
    htmlId = ko.observable<string>('');
    searchTerm = ko.observable('').extend({
        rateLimit: {
            timeout: 250,
            method: 'notifyWhenChangesStop'
        }
    });
    isSearching = ko.observable(false);
    addRemoveLock = false;  // guards add/remove against focus handlers
    hasMoreResults = ko.observable(false);
    loading = ko.observable(false);
    config: ko.Observable<FormSelectSearchConfiguration<T>>;
    enable: ko.Observable<boolean>;
    icon: ko.Observable<string>;

    validationTarget = ko.observable<ko.Observable<any> | null>(null);

    lastSearchId = 0;

    private configSubscriptions: ko.Subscription[] = [];
    private subscriptions: ko.Subscription[] = [];

    isExactMatch = ko.pureComputed(() => {
        return this._isExactMatch(this.searchTerm());
    });

    searchResults = ko.observableArray<T>();

    isEntitySelected = (entity: IdData) => {
        let entities = this.config().entities;

        if (!entities) {
            return false;
        }

        let found = false;

        entities().forEach(e => {
            if (unwrap(entity.id) === unwrap(e.id)) {
                found = true;
                return;
            }
        });

        return found;
    };

    // searchResults minus already selected entities
    notSelectedSearchResults = ko.pureComputed(() => {
        return this.searchResults().filter(e => ! this.isEntitySelected(e));
    });

    isSearchTermInvalid = ko.pureComputed(() => {
        return this.searchTerm() != null && this.searchTerm().trim() != '' && this.searchResults().length === 0;
    });

    constructor(params: FormSelectSearchInputParams<T>, componentInfo: ko.components.ComponentInfo) {
        let element = <Element>componentInfo.element;

        this.htmlId(element.getAttribute('input-id') ?? '');
        this.enable = params.enable === undefined || params.enable === null ? ko.observable(true) : asObservable(params.enable);
        this.icon = asObservable(params.icon);
        if (!this.icon()) {
            this.icon('add');
        }

        this.config = asObservable(params.config);
        this.subscriptions.push(this.config.subscribe(this.setupConfig.bind(this)));
        this.setupConfig(ko.unwrap(params.config));

        this.subscriptions.push(this.searchTerm.subscribe(this.search));
        this.subscriptions.push(this.isSearching.subscribe(this.onSearchingStateChanged));
    }

    private setupConfig(config: FormSelectSearchConfiguration<T>) {
        for (let subscription of this.configSubscriptions) {
            subscription.dispose();
        }
        this.configSubscriptions = [];

        if (config.entity) {
            this.onEntityChanged(config.entity());
            this.configSubscriptions.push(config.entity.subscribe(this.onEntityChanged));
        }

        this.validationTarget(config.entity || config.entities);
    }

    dispose() {
        for (let subscription of this.configSubscriptions) {
            subscription.dispose();
        }

        for (let subscription of this.subscriptions) {
            subscription.dispose();
        }
    }

    static createViewModel<T extends IdData>(params: FormSelectSearchInputParams<T>, componentInfo: ko.components.ComponentInfo) {
        return new FormSelectSearchInput(params, componentInfo);
    }

    onEntityChanged = (entity: T) => {
        if (entity) {
            this.searchTerm(this.name(entity));

            let obsv = this.config().entity;
            if (obsv && obsv.serverError) {
                obsv.serverError(null);
            }
        } else {
            this.searchTerm('');
        }
    }

    clearSearchTerm = () => {
        let config = this.config();

        if (config.entity) {
            if (config.confirmEditEntity) {
                config.confirmEditEntity().then(() => {
                    config.entity(null);
                    this.searchTerm('');
                });
                return;
            } else {
                config.entity(null);
            }
        }
        this.searchTerm('');
    }

    search = () => {
        this.findFirstEntitiesMatchingTerm(this.searchTerm()).then(() => {
            let emptySearchTerm = this.searchTerm().trim() === '';

            if (emptySearchTerm && this.canCreateEntity()
                    && this.notSelectedSearchResults().length === 0 ) {
                this.createEntity();
            }
        });
    }

    /**
     * Handle focus changes of the search input.
     *
     * When the input is focused we immediately begin searching.
     * When the input is blurred we do some housekeeping to ensure consistent
     * state.
     */
    onSearchingStateChanged = (isSearching: boolean) => {
        if (isSearching) {
            this.search();
        }

        // make sure we continue only if there is entity, search is not in
        // progress and add/remove is not in progress
        if (! this.config().entity || isSearching || this.addRemoveLock) {
            return;
        }

        let term = this.searchTerm();

        if (this._isExactMatch(term)) {
            return;
        }

        if (this.config().confirmEditEntity) {
            this.config().confirmEditEntity().then(() => {
                this.updateEntityBySearchTerm(term);
            }).catch(() => {
                this.resetSearchTerm();
            });
        } else {
            this.updateEntityBySearchTerm(term);
        }
    }

    private updateEntityBySearchTerm(term: string) {
        if (term.trim() === '') {
            this.config().entity(null);
        }

        let entities = this.searchResults();

        if (entities.length === 1 && this.name(entities[0]) === term) {
            this.config().entity(entities[0]);
        } else {
            this.config().entity(null);
            this.searchTerm('');
        }
    }

    private findFirstEntitiesMatchingTerm(term: string): Promise<void> {
        if (this._isExactMatch(term)) {
            this.searchResults([this.config().entity()]);
            this.hasMoreResults(false);
            return Promise.resolve(null);
        }

        term = term.trim().toLocaleLowerCase();

        let limit = 100;

        let results = this.config().list({ offset: undefined, limit: limit + 1, search_term: term });

        this.loading(true);

        let lastSearchId = ++this.lastSearchId;

        return results.then((results) => {
            if (this.lastSearchId != lastSearchId) {
                return;
            }

            this.loading(false);

            if (results.length > limit) {
                results = results.slice(0, limit);
                this.hasMoreResults(true);
            } else {
                this.hasMoreResults(false);
            }

            this.searchResults(results);
        });
    }

    private _isExactMatch(term: string) {
        let config = this.config();
        return config.entity && config.entity() && this.name(config.entity()) === term;
    }

    canCreateEntity() {
        return !!this.config().create;
    }

    createEntity = () => {
        let initialName = this.searchTerm().trim(); // grab name before blur() makes it go away
        $('input:focus').blur();

        this.addRemoveLock = true;

        let selectCreatedEntity = (entity: T) => {
            this.addEntity(config.create.instantiate ? config.create.instantiate(entity) : entity);
        };

        let config = this.config();
        let promise = app.formsStackController.push({
            title: config.create.title,
            name: config.create.componentName,
            params: $.extend({
                initialName,
                result: new Deferred<T>(),
                selectCreatedEntity: selectCreatedEntity
            }, config.create.extraParams)
        });

        promise.then((entity: T) => {
            this.addRemoveLock = false;
            selectCreatedEntity(entity);
        }).catch(() => {
            this.resetSearchTerm();
            this.addRemoveLock = false;
        });
    }

    hasAdvancedSearch() {
        return !!this.config().advancedSearch;
    }

    showAdvancedSearch = () => {
        $('input:focus').blur();

        this.addRemoveLock = true;

        let config = this.config();
        let promise = app.formsStackController.push({
            title: i18n.t('Advanced search')(),
            name: config.advancedSearch.componentName,
            isBig: true,
            params: $.extend({
                initialName: this.searchTerm().trim(),
                allowMultipleSelections: !!config.entities,
                initialMultipleSelections: config.entities ? config.entities().map(config.advancedSearch.serialize) : [],
                result: new Deferred<IdData | IdData[]>()
            }, config.advancedSearch.extraParams)
        });

        promise.then((result: IdData | IdData[]) => {
            if (result instanceof Array) {
                if (config.entities) {
                    let newEntities = result.map(config.advancedSearch.instantiate);
                    if (config.confirmChangeEntities) {
                        config.confirmChangeEntities().then(() => {
                            this.insertEntities(newEntities);
                            this.addRemoveLock = false;
                        }).catch(() => {
                            this.addRemoveLock = false;
                        });
                        return;
                    } else {
                        this.insertEntities(newEntities);
                    }
                }
            } else if (config.entity) {
                this.addEntity(config.advancedSearch.instantiate(result));
            }

            this.addRemoveLock = false;
        }).catch(() => {
            this.resetSearchTerm();
            this.addRemoveLock = false;
        });
    }

    /**
     * Handle selecting entity from the autocomplete list.
     *
     * Either mark entity as selected or add it to the list of selected
     * entities depending on the config.
     */
    addEntity = (entity: T) => {
        this.addRemoveLock = true;
        let config = this.config();

        if (config.entities) {
            for (let existingEntity of config.entities()) {
                if (ko.unwrap(entity.id) == ko.unwrap(existingEntity.id)) {
                    this.searchTerm('');
                    return;
                }
            }
            if (config.confirmChangeEntities) {
                config.confirmChangeEntities().then(() => {
                    this.insertEntity(entity);
                });
            } else {
                this.insertEntity(entity);
            }
            this.searchTerm('');
        } else if (config.entity) {
            if (config.confirmEditEntity) {
                config.confirmEditEntity().then(() => {
                    this.addRemoveLock = false;
                    config.entity(entity);
                }).catch(() => {
                    this.resetSearchTerm();
                    this.addRemoveLock = false;
                });
                return;
            } else {
                config.entity(entity);
            }
        }

        this.addRemoveLock = false;
    }

    private insertEntities(entities: T[]) {
        let config = this.config();
        if (config.create && config.create.insert) {
            config.entities([]);
            for (let entity of entities) {
                config.create.insert(entity);
            }
        } else {
            config.entities(entities);
        }
    }

    private insertEntity(entity: T) {
        let config = this.config();
        if (config.create && config.create.insert) {
            config.create.insert(entity);
        } else {
            config.entities.push(entity);
        }
    }

    private resetSearchTerm() {
        let config = this.config();

        if (!config.entity) {
            return;
        }

        if (config.entity()) {
            this.searchTerm(this.name(config.entity()));
        } else {
            this.searchTerm('');
        }
    }

    removeEntity = (entity: T) => {
        this.addRemoveLock = true;

        try {
            this.config().entities.remove(entity);
        } finally {
            this.addRemoveLock = false;
        }
    }

    private name(entity: T) {
        let name = this.config().getSummaryName(entity);
        return typeof name === 'string' ? name : translate(name);
    }
}

ko.components.register('form-select-search', { viewModel: { createViewModel: FormSelectSearchInput.createViewModel }, template: formSelectSearchTemplate });
