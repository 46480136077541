import * as ko from 'knockout';

import i18n from '../i18n';
import { translate, I18nText } from '../i18n_text';


ko.bindingHandlers['i18nText'] = {
    init: () => {
        return { controlsDescendantBindings: true };
    },
    update: (element: Element, valueAccessor: () => ko.Observable<I18nText | string> | I18nText | string) => {
        let value = ko.unwrap(valueAccessor());
        ko.utils.setTextContent(element, typeof value === 'string' ? value : translate(value));
    }
};

ko.virtualElements.allowedBindings['i18nText'] = true;

(<any>ko.validation.rules)['i18nTextRequired'] = {
    validator: (text: I18nText): boolean => {
        if (!text) {
            return false;
        }

        let defaultTr = text[text['default']];

        return !!text && !!defaultTr && !!defaultTr.trim();
    },
    message: i18n.t('This field is required')()
};
