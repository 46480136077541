import * as ko from 'knockout';

import { FileUploadDelegate } from './components/basic_widgets';

export interface FileUploadEndpoint {
    file_name: string;
    file_url: string;
    upload_url: string;
    upload_headers: { [key: string]: string };
}

export interface CloudStorageUploadDelegate {
    canReuseEndpoint: boolean;
    getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
    onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void;
}

export class CloudStorageUpload implements FileUploadDelegate {
    uploadEndpoint: FileUploadEndpoint | undefined = undefined;
    uploading = ko.observable(false);
    fileUploadError = ko.observable(false);

    constructor(private delegate: CloudStorageUploadDelegate) {
    }

    onFileContents = (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
        let uploadEndpointPromise: Promise<FileUploadEndpoint>;
        if (this.delegate.canReuseEndpoint && this.uploadEndpoint && this.uploadEndpoint.upload_headers['Content-Type'] === contentType) {
            uploadEndpointPromise = Promise.resolve(this.uploadEndpoint);
        } else {
            uploadEndpointPromise = this.delegate.getUploadEndpoint(contentType).then(data => {
                this.uploadEndpoint = data;
                return data;
            });
        }

        this.fileUploadError(false);
        this.uploading(true);
        uploadEndpointPromise.then(uploadEndpoint => {
            $.ajax(uploadEndpoint.upload_url, {
                method: 'PUT',
                data: fileContents,
                processData: false,
                headers: uploadEndpoint.upload_headers,
                xhr: prepareXHR,
                success: () => {
                    this.uploading(false);
                    if (this.uploadEndpoint) {
                        this.delegate.onFileUploaded(userFileName, this.uploadEndpoint.file_name, this.uploadEndpoint.file_url, contentType);
                    }
                },
                error: () => {
                    this.fileUploadError(true);
                    this.uploading(false);
                }
            });
        }).catch(() => {
            this.uploading(false);
        });
    }
}
