import * as ko from 'knockout';

import { FileUploadDelegate } from './components/basic_widgets';
import { CloudStorageUpload, FileUploadEndpoint, CloudStorageUploadDelegate } from './cloud_storage_upload';

export interface ImageUploadDelegate {
    getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
}

export class ImageUpload implements FileUploadDelegate, CloudStorageUploadDelegate {
    canReuseEndpoint = false;

    fileName: string | undefined = undefined;
    picturePublicURL = ko.observable<string | null>(null);

    private cloudUpload = new CloudStorageUpload(this);
    fileUploadError = this.cloudUpload.fileUploadError;
    uploading = this.cloudUpload.uploading;

    constructor(private delegate: ImageUploadDelegate) {
    }

    onFileContents = (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
        return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
    }

    getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
        return this.delegate.getImageUploadEndpoint(contentType);
    }

    onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
        this.fileName = fileName;
        this.picturePublicURL(publicURL);
    }
}
