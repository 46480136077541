import * as ko from 'knockout';

import { Chart, ChartPoint, ChartYAxe } from 'chart.js';

export interface BarChartConfig {
    type: 'bar' | 'line' | 'scatter',
    scaleType?: 'category' | 'time' | 'linear',
    title: string;
    aspectRatio?: number;
    yTitle?: string;
    yTitle2?: string;
    xTitle: string;
    labels: string[];
    fontSize: number;
    timeUnit?: 'day' | 'month',
    hideLegend?: boolean;
    datasets: {
        type?: 'bar' | 'line' | 'scatter',
        label: string;
        stack?: string;
        color_key?: string;
        gradient?: number;
        color?: string;
        second_axis?: boolean;
        data: number[] | { x: number; y: number; error?: number; }[];
    }[];
}

function stripedPattern(element: HTMLCanvasElement, color: string): CanvasPattern {
    const pattern = document.createElement('canvas');
    pattern.width = 16;
    pattern.height = 6;

    const patternCtx = pattern.getContext('2d');

    patternCtx.strokeStyle = color;
    patternCtx.lineWidth = 1;
    patternCtx.moveTo(0, 0);
    patternCtx.lineTo(16, 0);
    patternCtx.stroke();

    const ctx = element.getContext('2d');
    return ctx.createPattern(pattern, 'repeat');
}

function getColor(canvas: HTMLCanvasElement, color: string, gradient: number): string | CanvasPattern {
    if (gradient === undefined || gradient === null) {
        return color;
    }

    if (gradient > 1) {
        return stripedPattern(canvas, color);
    } else {
        const r = parseInt(color.slice(1, 3), 16);
        const g = parseInt(color.slice(3, 5), 16);
        const b = parseInt(color.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${gradient * 0.7 + 0.3})`;
    }
}

const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
const assignedColors: { [key: string]: number } = {};

function setup(element: Element, config: BarChartConfig) {
    let prevChart = (element as any).__prevChart as Chart;
    if (prevChart) {
        prevChart.destroy();
    }

    let idx = 0;
    let datasets = config.datasets.map(ds => {
        const colorKey = ds.color_key;
        if (assignedColors[colorKey] === undefined) {
            assignedColors[colorKey] = idx;
            idx = (idx + 1) % colors.length;
        }
        let color = getColor(element as HTMLCanvasElement, ds.color || colors[assignedColors[colorKey]], ds.gradient);

        let res: Chart.ChartDataSets;
        if ((ds.type || config.type) === 'line') {
            res = { lineTension: 0, borderColor: color, borderWidth: 2, backgroundColor: 'transparent', ...ds };
        } else if ((ds.type || config.type) === 'scatter') {
            res = {
                pointBorderColor: color,
                pointBorderWidth: 2,
                pointHoverBorderWidth: 2,
                pointBackgroundColor: 'transparent',
                pointRadius: 4,
                pointHoverRadius: 4,
                borderColor: color,
                borderWidth: 2,
                backgroundColor: 'transparent',
                ...ds
            };
        } else {
            res = { backgroundColor: color, ...ds };
        }
        res.maxBarThickness = 50;
        if (ds.second_axis) {
           res.yAxisID = 'y2';
        }

        return res;
    });

    let maxY = Number.MIN_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    for (let ds of datasets) {
        for (let pt of ds.data) {
            if (pt === null || pt === undefined) {
                continue;
            }

            let value = typeof pt === 'number' ? pt : (pt.y + ((pt as any).error || 0));
            maxY = Math.max(maxY, value);
            minY = Math.min(minY, value);
        }
    }

    let yAxes: ChartYAxe[] = [
        {
            scaleLabel: {
                display: true,
                labelString: config.yTitle || config.title,
                fontSize: config.fontSize
            },
            ticks: {
                fontSize: config.fontSize,
                suggestedMax: maxY,
                suggestedMin: minY,
                beginAtZero: true
            }
        }
    ];
    if (config.yTitle2) {
        yAxes[0].ticks.maxTicksLimit = 5;
        yAxes.push({
            id: 'y2',
            position: 'right',
            gridLines: {
                display: false
            },
            scaleLabel: {
                display: true,
                labelString: config.yTitle2,
                fontSize: config.fontSize
            },
            ticks: {
                fontSize: config.fontSize,
                maxTicksLimit: 5,
                beginAtZero: true
            }
        });
    }

    (element as any).__prevChart = new Chart(<HTMLCanvasElement>element, {
        type: config.type,
        data: {
            labels: config.labels,
            datasets
        },
        options: {
            aspectRatio: config.aspectRatio ?? 2,
            elements: {
                point: {
                    pointStyle: config.type === 'scatter' ? 'dash' : 'circle'
                }
            },
            tooltips: {
                mode: 'index',
                callbacks: {
                    label: (tooltipItem, data) => {
                        let dataset = data.datasets[tooltipItem.datasetIndex];
                        let category = dataset.label;
                        let value = dataset.data[tooltipItem.index];
                        let formattedValue: string;
                        if (typeof value !== 'number') {
                            let point = dataset.data[tooltipItem.index] as ChartPoint;
                            let x = point.x as number;
                            let y = point.y as number;

                            if ((dataset.type || config.type) === 'scatter') {
                                formattedValue = fmtNum(y) + ' / ' + fmtNum(x);
                            } else {
                                formattedValue = fmtNum(y);
                            }
                        } else {
                            formattedValue = fmtNum(value);
                        }
                        if (!category) {
                            return formattedValue;
                        }
                        return category + ': ' + formattedValue;
                    }
                }
            },
            title: { display: false },
            legend: {
                display: datasets.length > 1 && !config.hideLegend,
                labels: {
                    fontColor: 'rgba(0, 0, 0, 0.87)',
                    fontSize: config.fontSize
                }
            },
            scales: {
                xAxes: [{
                    type: config.scaleType || (config.type === 'bar' || config.type === 'scatter' ? 'category' : 'time'),
                    scaleLabel: {
                        display: true,
                        labelString: config.xTitle,
                        fontSize: config.fontSize
                    },
                    time: {
                        isoWeekday: true,
                        displayFormats: {
                            'week': '[W] WW/YYYY'
                        },
                        unit: config.timeUnit || 'day',
                        tooltipFormat: config.timeUnit === 'month' ? 'MMMM YYYY' : 'Do MMMM YYYY'
                    },
                    ticks: {
                        fontSize: config.fontSize
                    }
                }],
                yAxes
            }
        }
    });
}

ko.bindingHandlers['barChart'] = {
    update: (element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) => {
        setup(element, ko.unwrap(valueAccessor()));
    }
};

function fmtNum(value: number) {
    return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
