require('es6-promise').polyfill();
require('./ts/index.ts');
require('./styles/index.less');
require('@core_styles/vendor/materialize.css');
require('@core_js/vendor/materialize.js');
require('@core_js/vendor/picker.js'); // must come after materialize, to override the one included there
require('@core_js/vendor/picker.date.js');
require('firebaseui/dist/firebaseui.css');
require('./images/favicon.ico');
require('./images/logo_email.png');
require('./images/m1.png');
require('./images/m2.png');
require('./images/m3.png');
require('./images/m4.png');
require('./images/m5.png');
