import * as ko from 'knockout';

import { ProjectCategoryData, projectCategoriesApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { ProjectCategory } from '../models/project_category';

let template = require('../../templates/project_category_edit.html').default;

class ProjectCategoryEditScreen extends BaseEditScreen<ProjectCategoryData, ProjectCategory> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<ProjectCategoryData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: projectCategoriesApi, focus: true }, params, componentInfo);
    }

    instantiate(data: ProjectCategoryData) {
        return new ProjectCategory(data);
    }
}

export let projectCategoryEdit = { name: 'project-category-edit', viewModel: createWithComponent(ProjectCategoryEditScreen), template: template };

ko.components.register(projectCategoryEdit.name, projectCategoryEdit);
