import * as ko from 'knockout';

ko.bindingHandlers['textAreaAutoResize'] = {
    init: (element: Element, valueAccessor: () => ko.Observable<{}>) => {
        const resizeDetector = document.createElement('iframe');
        resizeDetector.frameBorder = '0'; // IE 11
        const textArea = element.querySelector('textarea');
        element.insertBefore(resizeDetector, element.firstChild);

        resizeDetector.contentWindow.onresize = () => {
            textareaAutoResize($(textArea));
        };

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            element.removeChild(resizeDetector);
        });
    },
    update: (element: Element, valueAccessor: () => ko.Observable<{}>) => {
        // setTimeout, so childrens are updated first
        setTimeout(() => textareaAutoResize($(element as HTMLElement).find('textarea')), 0);
    }
};

// NOTE: this is copy-pasted and adapted from materialize.js, because it's not an exported function

var hiddenDiv: JQuery;

function textareaAutoResize($textarea: JQuery) {
    if (!hiddenDiv) {
        hiddenDiv = $('.hiddendiv').first();
        if (!hiddenDiv.length) {
            hiddenDiv = $('<div class="hiddendiv common"></div>');
            $('body').append(hiddenDiv);
        }
    }

    // Set font properties of hiddenDiv

    var fontFamily = $textarea.css('font-family');
    var fontSize = $textarea.css('font-size');
    var lineHeight = $textarea.css('line-height');

    if (fontSize) { hiddenDiv.css('font-size', fontSize); }
    if (fontFamily) { hiddenDiv.css('font-family', fontFamily); }
    if (lineHeight) { hiddenDiv.css('line-height', lineHeight); }

    if ($textarea.attr('wrap') === "off") {
    hiddenDiv.css('overflow-wrap', "normal")
                .css('white-space', "pre");
    }

    hiddenDiv.text($textarea.val() + '\n');
    var content = hiddenDiv.html().replace(/\n/g, '<br>');
    hiddenDiv.html(content);


    // When textarea is hidden, width goes crazy.
    // Approximate with half of window size

    if ($textarea.is(':visible')) {
        let textWidth = $textarea.width();
        if (textWidth !== undefined) {
            hiddenDiv.css('width', textWidth);
        }
    }
    else {
        let windowWidth = $(window).width();
        if (windowWidth !== undefined) {
            hiddenDiv.css('width', windowWidth / 2);
        }
    }

    let divHeight = hiddenDiv.height();
    if (divHeight !== undefined) {
        $textarea.css('height', divHeight);
    }
}
