import * as ko from 'knockout';
import { ProductFileData, getProductDocUploadEndpoint } from '../api/simple_api';
import { FileUploadDelegate } from '@core/components/basic_widgets';
import { CloudStorageUploadDelegate, CloudStorageUpload, FileUploadEndpoint } from '@core/cloud_storage_upload';
import { serializeDateTime } from '@core/api/serialization';
import { session } from '@core/session';
import { asObservable, MaybeKO } from '@core/utils/ko_utils';
import i18n from '@core/i18n';

const template = require('../../templates/components/product_files.html').default;

class ProductFiles implements FileUploadDelegate, CloudStorageUploadDelegate {
    private phase: number;
    private files: ko.ObservableArray<ProductFileData>;
    private captionForFile = new Map<ProductFileData, ko.Observable<string>>();

    uploadTitle: string;
    accept: string;
    canReuseEndpoint = false;
    visibleFiles = ko.pureComputed(() => {
        if (this.phase === undefined || this.phase === null) {
            return this.files().map(this.addCaption);
        }

        return this.files().filter((file) => file.phase === this.phase).map(this.addCaption);
    });
    enable: KnockoutObservable<boolean>;

    private createCaptionObservable(file: ProductFileData){
        const caption = ko.observable(file.caption);
        caption.subscribe(caption => file.caption = caption);
        this.captionForFile.set(file, caption);
    }

    constructor(params: { phase: number, files: ko.ObservableArray<ProductFileData>, enable?: MaybeKO<boolean>, uploadTitle?: string, fileType?: 'images' | 'any' }) {
        this.uploadTitle = params.uploadTitle || i18n.t('Upload file')();
        if (params.fileType === 'images') {
            this.accept = 'image/*';
        } else {
            this.accept = '*/*';
        }
        this.phase = params.phase;
        this.files = params.files;
        for(const file of params.files()) {
            this.createCaptionObservable(file)
        }
        this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
    }

    addCaption = (file: ProductFileData) => ({
        ...file,
        caption: this.captionForFile.get(file),
    });

    removeFile = (fileToRemove: ProductFileData) => {
        this.files.remove((file) => file.id === fileToRemove.id);
    }

    isImage(file: ProductFileData) {
        return file.mime_type.indexOf('image/') === 0;
    }

    private cloudUpload = new CloudStorageUpload(this);
    fileUploadError = this.cloudUpload.fileUploadError;

    onFileContents = (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
        return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
    }

    getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
        return getProductDocUploadEndpoint(contentType);
    }

    onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
        const file: ProductFileData = {
            id: null,
            phase: this.phase,
            caption: '',
            file_name: fileName,
            user_file_name: userFileName,
            mime_type: contentType,
            url: publicURL,
            user_name: session.getName() ?? '',
            created_at: serializeDateTime(new Date())!
        }
        this.createCaptionObservable(file)
        this.files.push(file);
    }
}

export let productFiles = { name: 'product-files', viewModel: ProductFiles, template: template };

ko.components.register(productFiles.name, productFiles);
