import * as ko from 'knockout';
import { ChecklistData } from 'api/simple_api';
import i18n from '@core/i18n';

const CHECKLIST_ITEMS = [
    // one per phase
    [
        { slug: 'hypothesis_aligned', name: i18n.t('Innovation is relevant and within the scope of the stream.')() },
        { slug: 'project_manager_in_place', name: i18n.t('Project manager in place.')() },
    ],
    [
        { slug: 'baseline_defined', name: i18n.t('Performance of current practice indicated.')() },
        { slug: 'design_aligned', name: i18n.t('Right factors chosen to test the hypothesis.')() },
        { slug: 'project_and_budget_plan_in_place', name: i18n.t('Materials and budget done.')() },
    ],
    [
        { slug: 'field_trials_validates', name: i18n.t('Trial results shared with and approved by partners.')() },
        { slug: 'first_comparison', name: i18n.t('Target is met.')() },
    ],
    [
        { slug: 'positive_expansion', name: i18n.t('Positive expansion of experimental trials in farmers network and quantified impact for growers in an acceptable range.')() },
        { slug: 'analysis_warrants_implementation', name: i18n.t('Analysis of the market conditions warrant implementation.')() },
        { slug: 'cost_validated', name: i18n.t('Estimation of benefits. Cost of implementation fully factored in and validated by local growers.')() },
        { slug: 'supplier_sources_identified', name: i18n.t('Sources for suppliers are identified.')() },
    ],
    [
        { slug: 'accountabilities_clarified', name: i18n.t('Project manager accountabilities clarified for scale up.')() },
        { slug: 'investment_sources_identified', name: i18n.t('Investment sources and partner for scale up identified.')() },
        { slug: 'market_readiness_validated', name: i18n.t('Market readiness for new product profile validated.')() },
        { slug: 'time_to_scale_up_identified', name: i18n.t('Time to scale up identified.')() },
    ]
];
export const CHECKLIST_SUPERVISOR_USER_TYPE = 'coordinator';
export const CHECKLIST_PM_USER_TYPE = 'manager';
export type ChecklistUserType = 'manager' | 'coordinator';
const USER_TYPES: ChecklistUserType[] = [CHECKLIST_PM_USER_TYPE, CHECKLIST_SUPERVISOR_USER_TYPE];

export type ChecklistState = 'approved' | 'declined' | 'empty';

export interface ChecklistStateItem {
    userType: ChecklistUserType;
    value: ko.Observable<ChecklistState>;
}

export interface ChecklistRow {
    name: string;
    slug: string;
    states: ChecklistStateItem[];
}

export function makeChecklist(data: ChecklistData[]): ChecklistRow[][] {
    return CHECKLIST_ITEMS.map(slugs => {
        return slugs.map(slug => {
            return {
                ...slug,
                states: USER_TYPES.map(userType => {
                    let opt = data.filter(x => x.slug === slug.slug && x.user_type === userType)[0];
                    let state: ChecklistState = opt ? opt.state : 'empty';
                    return { userType, value: ko.observable(state) };
                })
            };
        });
    });
}

export function checklistToData(checklist: ChecklistRow[][]): ChecklistData[] {
    let res: ChecklistData[] = [];
    for (let phase of checklist) {
        for (let row of phase) {
            row.states.forEach((state, idx) => {
                let stateValue = state.value();
                if (stateValue !== 'empty') {
                    res.push({ slug: row.slug, user_type: USER_TYPES[idx], state: stateValue });
                }
            });
        }
    }

    return res;
}
