import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { User } from '../models/user';
import { UserData, usersApi } from '../api/simple_api';

let template = require('../../templates/users.html').default;

class UsersScreen implements ListLoaderDelegate<UserData, User> {
    fetch(params: ListRequestParams) {
        return usersApi.list(params);
    }

    instantiate(userData: UserData) {
        return new User(userData);
    }

    getEditUrl(entity: User) {
        return entity.editUrl();
    }

    remove(id: string) {
        return usersApi.remove(id);
    }

    canRemove(user: User) {
        return true;
    }
}

export let users = { name: 'users', viewModel: UsersScreen, template: template };

ko.components.register(users.name, users);
