import * as ko from 'knockout';

import { FactorDefData, FactorDefChoiceData, ProjectCategoryData, FactorType } from '../api/simple_api';
import { I18nText, asI18nText } from '@core/i18n_text';
import i18n from '@core/i18n';
import { getProjectCategorySearch } from './helpers/search_configs';

export const FACTOR_TYPE_OPTIONS = [
    { id: 'decimal', title: i18n.t('Decimal number')() },
    { id: 'integer', title: i18n.t('Integer number')() },
    { id: 'string', title: i18n.t('Text')() },
    { id: 'date', title: i18n.t('Date')() },
    { id: 'document', title: i18n.t('Document/Image')() },
    { id: 'choice', title: i18n.t('Choice')() }
];

export class FactorDef {
    id: string | null = null;

    typeOptions = FACTOR_TYPE_OPTIONS;

    nameJson = ko.observable<I18nText>(asI18nText('')).extend({
        i18nTextRequired: true
    });
    category = ko.observable<ProjectCategoryData | null>(null).extend({
        required: true
    });
    helpTextJson = ko.observable<I18nText>(asI18nText(''));
    type = ko.observable<FactorType>('decimal');
    choices = ko.observableArray<FactorDefChoice>();

    categorySearch = getProjectCategorySearch(this.category);

    canEdit = true;

    constructor(data: FactorDefData) {
        if (data) {
            this.id = data.id;
            this.nameJson(data.name_json);
            this.category(data.category);
            this.helpTextJson(data.help_text_json);
            this.type(data.type);
            this.choices(data.choices.map(choice => new FactorDefChoice(choice)));

            this.canEdit = !data.in_use;
        }
    }

    addChoice = () => {
        this.choices.push(new FactorDefChoice());
    }

    removeChoice = (choice: FactorDefChoice) => {
        this.choices.remove(choice);
    }

    toData(): FactorDefData {
        return {
            id: this.id,
            name_json: this.nameJson(),
            category: this.category()!,
            help_text_json: this.helpTextJson(),
            type: this.type(),
            choices: this.choices().map(choice => choice.toData())
        };
    }
}

export class FactorDefChoice {
    private id: string | null = null;

    nameJson = ko.observable<I18nText>(asI18nText('')).extend({
        i18nTextRequired: true
    });

    constructor(data?: FactorDefChoiceData) {
        if (data) {
            this.id = data.id;
            this.nameJson(data.name_json);
        }
    }

    toData(): FactorDefChoiceData {
        return {
            id: this.id,
            name_json: this.nameJson()
        };
    }
}
