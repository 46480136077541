import * as ko from 'knockout';

import { User } from '../models/user';
import { UserData, usersApi } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';

let template = require('../../templates/user_edit.html').default;

class UserEditScreen extends BaseEditScreen<UserData, User> {
    constructor(params: { id: string, initialName?: string, result?: Deferred<UserData> }, componentInfo: ko.components.ComponentInfo) {
        super({ api: usersApi, focus: true }, params, componentInfo);
    }

    shouldDisplaySyncLabel(): Boolean {
        return this.entity().isFieldtrialsSynced();
    }

    instantiate(data: UserData): User {
        return new User(data);
    }
}

export let userEdit = { name: 'user-edit', viewModel: createWithComponent(UserEditScreen), template: template };

ko.components.register(userEdit.name, userEdit);
