import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { PartnerData, partnersApi } from '../api/simple_api';

let template = require('../../templates/partners.html').default;

class PartnersScreen implements ListLoaderDelegate<PartnerData> {
    fetch(params: ListRequestParams) {
        return partnersApi.list(params);
    }

    instantiate(data: PartnerData) {
        return data;
    }

    getEditUrl(entity: PartnerData): string {
        return '/partners/' + entity.id + '/';
    }

    remove(id: string) {
        return partnersApi.remove(id);
    }

    canRemove(data: PartnerData) {
        return true;
    }
}

export let partners = { name: 'partners', viewModel: PartnersScreen, template: template };

ko.components.register(partners.name, partners);
