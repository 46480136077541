import * as ko from 'knockout';
import { Point } from './map_location';

declare const google: any;

ko.bindingHandlers['mapSearch'] = {
    init: (element: Element, valueAccessor: () => ko.Observable<Point>) => {
        let obs = valueAccessor();

        let autocomplete = new google.maps.places.Autocomplete(element);
        autocomplete.setFields(['geometry']);

        let listener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
            let place = autocomplete.getPlace();
            if (place && place.geometry) {
                obs({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
            }
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
            google.maps.event.removeListener(listener);
        });
    }
};
