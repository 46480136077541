import * as ko from "knockout";

import { checkLogin, UserRole } from "./api/check";
import { session } from "@core/session";

export const userRole = ko.observable<UserRole | null>(null);

export function initSession() {
  if (SANDBOX) {
    // TODO: Set sandbox Firebase config. LOCAL
    session.init(userRole, checkLogin, {
      apiKey: "AIzaSyBeLAMPvEhPT63lHwuZ-885TnFsvsxXh4s",
      authDomain: "gate-tool.firebaseapp.com",
      projectId: "gate-tool",
      storageBucket: "gate-tool.firebasestorage.app",
      messagingSenderId: "909859793305",
      appId: "1:909859793305:web:97e3d91dad8398ff3e0b08",
    });
  } else {
    // TODO: Set sandbox Firebase config. PROD
    session.init(userRole, checkLogin, {
      apiKey: "AIzaSyBeLAMPvEhPT63lHwuZ-885TnFsvsxXh4s",
      authDomain: "gate-tool.firebaseapp.com",
      projectId: "gate-tool",
      storageBucket: "gate-tool.firebasestorage.app",
      messagingSenderId: "909859793305",
      appId: "1:909859793305:web:97e3d91dad8398ff3e0b08",
    });
  }
}
