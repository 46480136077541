import * as ko from 'knockout';
import i18n from '../i18n';
import { I18nText, LANGUAGES } from '../i18n_text';
import { asObservable, MaybeKO, createWithComponent } from '../utils/ko_utils';

let template = require('../../templates/components/i18n_text_input.html').default;


class Translation {
    value = ko.observable<string>();
    language = '';

    constructor(text: I18nText, language: string) {
        this.value(text[language] || '');
        this.language = language;
    }
}

interface I18nTextInputParams {
    value: ko.Observable<I18nText>;
    enable?: MaybeKO<boolean>;
}

class I18nTextInput {
    static sessionLanguages = ko.observableArray<string>();

    private subscriptions: ko.Subscription[] = [];
    private ignoreValueChange = false;

    value: ko.Observable<I18nText>;
    enable: ko.Observable<boolean>;
    mainLanguage: ko.Observable<string>;
    showDetails: ko.Observable<boolean>;
    forceShowDetails = ko.observable(false);

    mainValue = ko.observable<string>();
    translations = ko.observableArray<Translation>();

    addLanguage = ko.observable<string | null>(null);

    markAsDefault = i18n.t('Mark this language as default')();
    showMoreLanguages = i18n.t('Show more languages')();

    constructor(params: I18nTextInputParams) {
        this.value = params.value;
        this.enable = params.enable === undefined ? ko.observable(true) : asObservable(params.enable);
        this.mainLanguage = i18n.userInputLanguage;
        this.showDetails = i18n.showDetails;

        this.subscriptions.push(I18nTextInput.sessionLanguages.subscribe(this.reset));
        this.subscriptions.push(this.mainLanguage.subscribe(this.reset));
        this.subscriptions.push(this.value.subscribe(this.reset));
        this.subscriptions.push(this.mainValue.subscribe(this.updateValue));
        this.subscriptions.push(this.addLanguage.subscribe(this.onAddLanguage));

        this.reset();
    }

    dispose() {
        for (let sub of this.subscriptions) {
            sub.dispose();
        }
    }

    private updateValue = () => {
        if (this.ignoreValueChange) {
            return;
        }

        let value: I18nText = {
            default: this.value() ? this.value().default : this.mainLanguage()
        };

        value[this.mainLanguage()] = this.mainValue();
        for (let translation of this.translations()) {
            let trValue = (translation.value() || '').trim();
            if (trValue) {
                value[translation.language] = trValue;
            }
        }

        this.ignoreValueChange = true;
        this.value(value);
        this.ignoreValueChange = false;
    }

    private reset = () => {
        if (this.ignoreValueChange) {
            return;
        }

        let text = this.value() || { default: this.mainLanguage() };

        this.addLanguages(text);
        this.ignoreValueChange = true; // avoid call to updateValue
        this.mainValue(text[this.mainLanguage()] || '');
        this.ignoreValueChange = false;

        let translations: Translation[] = [];

        for (let language of I18nTextInput.sessionLanguages()) {
            if (language === this.mainLanguage()) {
                continue;
            }

            let translation = new Translation(text, language);
            translations.push(translation);

            this.subscriptions.push(translation.value.subscribe(this.updateValue));
        }

        this.translations(translations);

        this.forceShowDetails(!this.mainValue() && this.translations().some(tr => !!tr.value()));
    }

    private addLanguages(text: I18nText) {
        let languages = I18nTextInput.sessionLanguages().slice();
        let didChange = false;

        for (let language in text) {
            if (!text.hasOwnProperty(language)
                || language === 'default'
                || languages.indexOf(language) !== -1) {
                continue;
            }

            didChange = true;
            languages.push(language);
        }

        if (didChange) {
            languages.sort();
            I18nTextInput.sessionLanguages(languages);
        }
    }

    onToggleDetails = () => {
        i18n.setShowDetails(!(this.showDetails() || this.forceShowDetails()));
        this.forceShowDetails(false);
    }

    addLanguageList = ko.pureComputed(() => {
        let existing = I18nTextInput.sessionLanguages();
        let emptyOption = { name: i18n.t('Add translation')(), value: null as string | null };

        return [emptyOption].concat(LANGUAGES.filter(
            lang => lang[0] !== this.mainLanguage() && existing.indexOf(lang[0]) === -1
        ).map(lang => {
            return {
                name: lang[1],
                value: lang[0]
            };
        }));
    });

    onAddLanguage = (value: string | null) => {
        if (!value) {
            return;
        }

        let languages = I18nTextInput.sessionLanguages;

        if (languages.indexOf(value) !== -1) {
            return;
        }

        languages.push(value);
        languages.sort();

        this.addLanguage(null);
    }
}

ko.components.register('i18n-text-input', { viewModel: createWithComponent(I18nTextInput), template: template });
