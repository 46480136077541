import * as ko from 'knockout';

import { MaybeKO, createWithComponent } from '../utils/ko_utils';

let template = require('../../templates/components/form_nested_entities.html').default;

interface FormNestedEntityAction<T> {
    callback(entity: T): void;
    icon: string;
}

export interface FormNestedEntitiesConfiguration<T> {
    title: MaybeKO<string>;
    addTitle: MaybeKO<string>;
    missingTitle: MaybeKO<string>;
    infoText?: MaybeKO<string>;

    entities: ko.Observable<T[]>;

    canRemove(entity: T): boolean;

    add(): T;
    remove(entity: T): void;

    hasErrors(entity: T): boolean;
    showErrors(entity: T): void;

    actions: FormNestedEntityAction<T>[];

    getSummaryName(entity: T): string;
}

export class FormNestedEntities {
    config: FormNestedEntitiesConfiguration<{}>;
    selectedEntity = ko.observable<{} | null>(null);

    constructor(params: { config: FormNestedEntitiesConfiguration<{}> }) {
        this.config = params.config;
    }

    add = () => {
        let entity = this.config.add();
        this.selectedEntity(entity);
    };

    remove = (entity: {}, event: Event) => {
        event.stopPropagation();

        if (this.selectedEntity() == entity) {
            this.selectedEntity(null);
        }

        this.config.remove(entity);
    };

    invokeAction = (entity: {}, action: FormNestedEntityAction<{}>, evt: Event) => {
        evt.stopPropagation();
        action.callback(entity);
    };

    isSelected = (entity: {}) => {
        return this.selectedEntity() == entity;
    };

    showErrorsInTitle = (entity: {}) => {
        return !this.isSelected(entity) && this.config.hasErrors(entity);
    };

    toggleSelection = (entity: {}) => {
        if (this.isSelected(entity)) {
            this.selectedEntity(null);
        } else {
            this.selectedEntity(entity);
            this.config.showErrors(entity);
        }
    };

    hasSummaryName(entity: {}): boolean {
        return !!this.config.getSummaryName(entity);
    }
}

ko.components.register('form-nested-entities', { viewModel: createWithComponent(FormNestedEntities), template: template });
