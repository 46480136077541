import * as ko from 'knockout';

import i18n from '@core/i18n';
import { downloadBlob } from '@core/utils';
import { session } from '@core/session';
import { asObservable } from '@core/utils/ko_utils';
import { FileUploadDelegate } from '@core/components/basic_widgets';

let template = require('../../templates/components/import_entities.html').default;

export interface ImportEntitiesDelegate {
    title: ko.Observable<string>;
    description: string;
    backTitle: string;
    loading?: ko.Observable<boolean>;

    templateBaseName: string;
    downloadTemplate: () => Promise<Blob>;
    getImportUrl: () => string;
    onSuccess?: () => void;
}

type ImportState = 'initial' | 'preparing' | 'importing' | 'imported';

interface ImportError {
    sheet: string;
    cell: string;
    message: string;
}

class ImportEntities implements FileUploadDelegate {
    private delegate: ko.Observable<ImportEntitiesDelegate>;

    private defaultError = i18n.t('Couldn\'t upload Excel file. Please check your internet connection and try again.')();
    preparingText = i18n.t('Preparing Excel template...');
    importingText = i18n.t('Importing Excel file...');

    loading = ko.pureComputed(() => this.delegate().loading?.() ?? false);

    state = ko.observable<ImportState>('initial');
    errors = ko.observableArray<ImportError>();
    excelUploadError = ko.observable('');

    constructor(params: { delegate: ko.Observable<ImportEntitiesDelegate> }) {
        this.delegate = asObservable(params.delegate);
    }

    dispose() {
    }

    goBack = () => {
        history.back();
    }

    downloadTemplate = () => {
        this.state('preparing');

        this.delegate().downloadTemplate().then(data => {
            this.state('initial');
            downloadBlob(data, this.delegate().templateBaseName + '.xls');
        }).catch(() => {
            this.state('initial');
        });
    }

    onFileContents = (userFileName: string, fileContents: string, contentType: string, prepareXHR: () => XMLHttpRequest) => {
        this.excelUploadError('');
        this.errors([]);

        session.getToken().then(token => {
            $.ajax(this.delegate().getImportUrl(), {
                method: 'PUT',
                data: fileContents,
                processData: false,
                headers: {
                    'Authorization': token,
                    'X-User-Language': localStorage.getItem('userLanguage'),
                    'Content-Disposition': 'attachment; filename="file"'
                },
                dataType: 'json',
                xhr: () => {
                    let xhr = prepareXHR();
                    xhr.upload.addEventListener('load', (event: ProgressEvent) => {
                        let percent = Math.ceil(event.loaded / event.total * 100);

                        if (percent >= 100) {
                            this.state('importing');
                        }
                    });

                    return xhr;
                },
                success: (errors: ImportError[]) => {
                    if (errors.length === 0) {
                        this.state('imported');
                        this.delegate().onSuccess?.();
                    } else {
                        this.state('initial');
                        this.excelUploadError(i18n.t('The uploaded Excel file is invalid. Please correct the following errors and re-upload the file:')());
                        this.errors(errors);
                    }
                },
                error: (xhr) => {
                    if (xhr.status === 400 && xhr.responseJSON instanceof Array) {
                        this.excelUploadError(xhr.responseJSON.join(' '));
                    } else {
                        this.excelUploadError(this.defaultError);
                    }
                    this.state('initial');
                }
            });
        }).catch(() => {
            this.excelUploadError(this.defaultError);
        });
    }
}

let importEntities = { name: 'import-entities', viewModel: ImportEntities, template: template };

ko.components.register(importEntities.name, importEntities);
