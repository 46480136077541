import * as ko from 'knockout';

ko.bindingHandlers['formattedNumber'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<number | string> | number | string) => {
        updateWithNumber(element, ko.unwrap(valueAccessor()), undefined);
    }
};

ko.bindingHandlers['formattedCurrency'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<number | string> | number | string) => {
        updateWithNumber(element, ko.unwrap(valueAccessor()), 2);
    }
};

ko.bindingHandlers['formattedPercentage'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<number | string> | number | string) => {
        updateWithNumber(element, ko.unwrap(valueAccessor()), 2, '%');
    }
};

ko.bindingHandlers['formattedInt'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<number | string> | number | string) => {
        updateWithNumber(element, ko.unwrap(valueAccessor()), 0);
    }
};

ko.bindingHandlers['formattedRoundedNum'] = {
    update: (element: Element, valueAccessor: () => ko.Observable<number | string> | number | string) => {
        let num = ko.unwrap(valueAccessor());
        if (num >= 1 || num <= -1) {
            updateWithNumber(element, num, 0);
        } else {
            updateWithNumber(element, num, undefined);
        }
    }
};

function updateWithNumber(element: Element, value: number | string, precision: number | undefined, suffix?: string) {
    if (value === '' || value === null || value === undefined) {
        element.textContent = 'N/A';
    } else {
        let options = precision === undefined ? undefined : { minimumFractionDigits: precision, maximumFractionDigits: precision };
        element.textContent = (typeof value === 'number' ? value : parseFloat(value)).toLocaleString(undefined, options) + (suffix || '');
    }
}
