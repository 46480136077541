import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Deferred } from '../utils/deferred';
import { I18nText, asI18nText } from '../i18n_text';
import { SimpleApi } from '../api/simple_api';

interface BaseModel<TData> {
    name?: ko.Observable<string>;
    nameJson?: ko.Observable<I18nText>;
    toData(): TData;
    dispose?(): void;
}

export abstract class BaseEditScreen<TData extends { id: string }, T extends BaseModel<TData>> extends BaseForm<TData> {
    entity = ko.validatedObservable<T>(undefined);
    globalError = ko.observable('');

    constructor(private options: { api: SimpleApi<TData>, focus: boolean }, params: { id: string, initialName?: string, result?: Deferred<TData> }, componentInfo: ko.components.ComponentInfo) {
        super(params);

        let dataPromise = params.id ? options.api.retrieve(params.id) : undefined;
        let promise = Promise.all([dataPromise]).then(([data]) => {
            let entity = this.instantiate(data);
            this.entity(entity);

            if (!data && entity.name && params.initialName) {
                entity.name(params.initialName);
            }
            if (!data && entity.nameJson && params.initialName) {
                entity.nameJson(asI18nText(params.initialName));
            }
        });
        this.loadedAfter(promise).then(() => { if (this.options.focus) { this.focusFirst(componentInfo.element) } });
    }

    dispose() {
        let entity = this.entity();
        if (entity.dispose) {
            entity.dispose();
        }
    }

    abstract instantiate(data: TData | undefined): T;

    save = () => {
        this.saveEntity(this.options.api, this.entity, this.globalError);
    }
}
