import * as ko from 'knockout';

import { FactorDefData, factorDefsApi, ProjectCategoryData } from '../api/simple_api';
import { Deferred } from '@core/utils/deferred';
import { createWithComponent } from '@core/utils/ko_utils';
import { BaseEditScreen } from '@core/screens/base_edit';
import { FactorDef } from '../models/factor_def';

let template = require('../../templates/factor_def_edit.html').default;

class FactorDefEditScreen extends BaseEditScreen<FactorDefData, FactorDef> {
    private getCategory: () => ProjectCategoryData;

    constructor(params: { id: string, initialName?: string, getCategory?: () => ProjectCategoryData, result?: Deferred<FactorDefData> }, componentInfo: ko.components.ComponentInfo) {
        const result = params.result ? new Deferred<FactorDefData>() : null;
        super({ api: factorDefsApi, focus: true }, { ...params, result }, componentInfo);
        this.getCategory = params.getCategory;

        result?.promise.then(async data => params.result?.resolve(await factorDefsApi.retrieve(data.id)));
        result?.promise.catch(() => params.result?.reject());
    }

    instantiate(data: FactorDefData) {
        let def = new FactorDef(data);
        if (!data && this.getCategory) {
            def.category(this.getCategory());
        }

        return def;
    }
}

export let factorDefEdit = { name: 'factor-def-edit', viewModel: createWithComponent(FactorDefEditScreen), template: template };

ko.components.register(factorDefEdit.name, factorDefEdit);
