import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';
import { createWithComponent } from '../utils/ko_utils';
import { Point } from '../ko_bindings/map_location';

let template = require('../../templates/components/map.html').default;

class Map {
    placeholderText = i18n.t('Search location')();

    obs: ko.Observable<Point>;
    others: Point[];
    enable: boolean;
    result: Deferred<{} | null> | undefined;

    constructor(params: { value: ko.Observable<Point>, others: Point[], enable: boolean, result?: Deferred<{} | null> }) {
        this.obs = params.value;
        this.others = params.others;
        this.enable = params.enable;
        this.result = params.result;
    }

    onClose = () => {
        this.result?.resolve(null);
    }
}

ko.components.register('map', { viewModel: createWithComponent(Map), template: template });

export function selectLocationPopup(obs: ko.Observable<Point | null>, others: Point[], enable: boolean) {
    app.formsStackController.push<Point | null, { value: ko.Observable<Point | null>, others: Point[], enable: boolean, result: Deferred<Point | null> }>({
        title: i18n.t('Location')(),
        name: 'map',
        isBig: true,
        params: {
            value: obs,
            others: others,
            enable: enable,
            result: new Deferred<Point | null>()
        }
    });
}
