// import * as Sentry from '@sentry/browser';
// import { BrowserTracing } from '@sentry/tracing';

export function setupErrorLogging() {
    if(process.env.NODE_ENV === "production") { 
        // Sentry.init({
        //     dsn: '', # Set
        //     integrations: [new BrowserTracing()],
        //     tracesSampleRate: 1.0,
        // });
    }
}