import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { RemoveResult } from '../api/request';
import i18n from '../i18n';
import { createWithComponent } from '../utils/ko_utils';

let template = require('../../templates/components/remove_dialog.html').default;

class RemoveDialog {
    name: string;
    private remove: () => Promise<RemoveResult>;
    private result: Deferred<{} | null>;

    deleting = ko.observable(false);
    removeResult = ko.observable<RemoveResult | null>();

    constructor(params: { name: string, remove: () => Promise<RemoveResult>, result: Deferred<{} | null> }) {
        this.name = params.name;
        this.remove = params.remove;
        this.result = params.result;
    }

    onConfirm = () => {
        this.deleting(true);
        this.removeResult(null);

        this.remove().then(data => {
            this.deleting(false);

            if (data.did_delete) {
                this.result.resolve(null);
            } else {
                this.removeResult(data);
            }
        }).catch(() => {
            this.deleting(false);
        })
    }

    onCancel = () => {
        this.result.reject();
    }
}

ko.components.register('remove-dialog', { viewModel: createWithComponent(RemoveDialog), template: template });

export function removeDialog(name: string, remove: () => Promise<RemoveResult>): Promise<{}> {
    return app.formsStackController.push({
        title: i18n.t('Confirm')(),
        name: 'remove-dialog',
        params: { name, remove, result: new Deferred<{}>() }
    });
}
