import { ListRequestParams, request, listRequest, ValidationResult, requestWithValidation, RemoveResult, requestRemoveEntity, listParamsToQueryString } from './request';

export class SimpleApi<T extends { id: string | null }, LRP extends ListRequestParams = ListRequestParams, ListData = T, ResponseData = { id: string }> {
    constructor(private baseName: string, private apiParams?: {}) {
    }

    list(params: LRP): Promise<ListData[]> {
        return listRequest('/api/' + this.baseName + '/', { ...params, ...(this.apiParams || {}) });
    }

    retrieve(id: string): Promise<T> {
        return request('GET', this.addApiParams('/api/' + this.baseName + '/' + id + '/'));
    }

    save(data: T): Promise<ValidationResult<ResponseData>> {
        let endpoint = '/api/' + this.baseName + '/';
        let method = 'POST';
        if (data.id) {
            endpoint += data.id + '/';
            method = 'PUT';
        }

        return requestWithValidation<ResponseData>(method, this.addApiParams(endpoint), data);
    }

    remove(id: string): Promise<RemoveResult> {
        return requestRemoveEntity(this.addApiParams('/api/' + this.baseName + '/' + id + '/'));
    }

    private addApiParams(url: string) {
        if (this.apiParams) {
            url = url + '?' + listParamsToQueryString(this.apiParams);
        }
        return url;
    }
}
