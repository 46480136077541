import * as ko from "knockout";

import { ListLoaderDelegate } from "@core/components/list_loader";
import { BaseLoadingScreen } from "@core/screens/base_loading_screen";
import { documentationApi, DocumentationData } from "api/simple_api";
import { ListRequestParams, RemoveResult } from "@core/api/request";
import {
  canAddDocumentation,
  canEditDocumentation,
  canRemoveDocumentation,
} from "permissions";

let template = require("../../templates/documentation.html").default;

class DocumentationScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<DocumentationData>
{
  fetch(params: ListRequestParams): Promise<DocumentationData[]> {
    return documentationApi.list(params);
  }

  instantiate(data: DocumentationData): DocumentationData {
    return data;
  }

  remove(id: string): Promise<RemoveResult> {
    return documentationApi.remove(id);
  }

  canRemove(entity: DocumentationData): boolean {
    return canRemoveDocumentation();
  }

  canAdd(): boolean {
    return canAddDocumentation();
  }

  canEdit(): boolean {
    return canEditDocumentation();
  }
}

export let documentation = {
  name: "documentation",
  viewModel: DocumentationScreen,
  template: template,
};

ko.components.register(documentation.name, documentation);
