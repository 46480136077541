import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate, ListLoader } from '@core/components/list_loader';
import { businessLinesApi, BusinessLineListData, CountryData, ProjectCategoryData, TagData, countriesApi, projectCategoriesApi, tagsApi, BusinessLineFilters, BusinessLineIncludeType } from '../api/simple_api';
import { translate } from '@core/i18n_text';
import { BaseLoadingScreen } from '@core/screens/base_loading_screen';
import { parseDate, serializeDate } from '@core/api/serialization';
import { FilterDelegate } from '@core/components/list_filters';
import i18n from '@core/i18n';
import { deflateList, updateLocationWithQueryString, asArray, readFromLocalStorage } from '@core/utils';
import { Action } from '@core/components/basic_widgets';
import { app } from '@core/app';
import { Deferred } from '@core/utils/deferred';
import { getPhaseName } from './business_lines';

let template = require('../../templates/business_lines_by_role.html').default;

interface FilterParams {
    name: string;
    modified_after: string;
    country_ids: string | string[];
    created_after: string;
    category_ids: string | string[];
    tag_ids: string | string[];
}

class BusinessLinesByRoleScreen extends BaseLoadingScreen implements ListLoaderDelegate<BusinessLineListData> {
    private filtersKey: string;
    private showArchivedChoices = [
        { name: i18n.t('No')(), id: 'false' },
        { name: i18n.t('Yes')(), id: 'true' },
    ];

    private nameFilter = ko.observable('');
    private modifiedFilter = ko.observable<Date>();
    private countryFilters = ko.observableArray<CountryData>();
    private createdFilter = ko.observable<Date>();
    private categoryFilters = ko.observableArray<ProjectCategoryData>();
    private tagFilters = ko.observableArray<TagData>();
    private showArchived = ko.observable(this.showArchivedChoices[0]);

    include: BusinessLineIncludeType;
    private loader: ListLoader<BusinessLineListData, BusinessLineListData>;

    newFilters: FilterDelegate[] = [
        { value: this.modifiedFilter, title: i18n.t('Modified after')() },
        { list: countriesApi.list.bind(countriesApi), entities: this.countryFilters, title: i18n.t('Country')() },
        { value: this.createdFilter, title: i18n.t('Created after')() },
        { list: projectCategoriesApi.list.bind(projectCategoriesApi), entities: this.categoryFilters, title: i18n.t('Categories')() },
        { list: tagsApi.list.bind(tagsApi), entities: this.tagFilters, title: i18n.t('Tags')() },
        { value: this.showArchived, choices: this.showArchivedChoices, title: i18n.t('Show archived')() },
    ];
    baseUrl: string;

    constructor(params: { filters: FilterParams, include: BusinessLineIncludeType }) {
        super();

        this.filtersKey = `business-lines-${params.include}-filters-v1`; // update when format is not compatible with existing saved formats
        this.include = params.include;
        this.baseUrl = params.include === 'project_manager' ? '/pm_innovations/' : '/supervisor_innovations/';

        if (!params.filters.name && !params.filters.modified_after && !params.filters.country_ids && !params.filters.created_after && !params.filters.category_ids && !params.filters.tag_ids) {
            params.filters = readFromLocalStorage(this.filtersKey);
        }

        this.nameFilter(params.filters.name || '');
        // set afterwards, or we'd have to wait for throttle before updating initial value
        this.nameFilter = this.nameFilter.extend({ throttle: 300 });
        this.newFilters.splice(0, 0, { textValue: this.nameFilter, title: i18n.t('Name')() });

        let countryIds = asArray(params.filters.country_ids);
        let categoryIds = asArray(params.filters.category_ids);
        let tagIds = asArray(params.filters.tag_ids);

        let countryPromise = countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
        let categoryPromise = categoryIds.length > 0 ? projectCategoriesApi.list({ ids: categoryIds }) : Promise.resolve<ProjectCategoryData[]>([]);
        let tagPromise = tagIds.length > 0 ? tagsApi.list({ ids: tagIds }) : Promise.resolve<TagData[]>([]);

        let promise = Promise.all([countryPromise, categoryPromise, tagPromise]).then(([countries, categories, tags]) => {
            this.countryFilters(countries);
            this.categoryFilters(categories);
            this.tagFilters(tags);
            this.modifiedFilter(parseDate(params.filters.modified_after));
            this.createdFilter(parseDate(params.filters.created_after));
        });
        this.loadedAfter(promise);
    }

    onReady(loader: ListLoader<BusinessLineListData, BusinessLineListData>) {
        this.loader = loader;
    }

    fetch(params: ListRequestParams) {
        let filters: BusinessLineFilters = {
            name: this.nameFilter(),
            country_ids: deflateList(this.countryFilters),
            phases: [],
            owner_ids: [],
            category_ids: deflateList(this.categoryFilters),
            tag_ids: deflateList(this.tagFilters),
            modified_after: serializeDate(this.modifiedFilter()),
            created_after: serializeDate(this.createdFilter()),
            include: this.include,
            show_archived: this.showArchived().id === 'true',
        };
        updateLocationWithQueryString(filters);
        window.localStorage.setItem(this.filtersKey, JSON.stringify(filters));

        return businessLinesApi.list({ ...params, ...filters });
    }

    instantiate(data: BusinessLineListData) {
        return data;
    }

    getEditUrl(entity: BusinessLineListData): string {
        return this.baseUrl + entity.id + '/';
    }

    getName(entity: BusinessLineListData): string {
        return entity.name + '/' + entity.country.name;
    }

    getCreationYear(entity: BusinessLineListData): string {
        let start = parseDate(entity.phase_1_changed_at);
        if (start) {
            return start.getFullYear().toString();
        } else {
            return 'N/A';
        }
    }

    getCategories(entity: BusinessLineListData): string {
        return entity.categories.map(cat => translate(cat.name_json)).join(', ');
    }

    getTags(entity: BusinessLineListData): string {
        return entity.tags.map(tag => tag.name).join(', ');
    }

    getPhaseName(entity: BusinessLineListData) {
        return getPhaseName(entity.cur_phase);
    }

    remove(id: string) {
        return businessLinesApi.remove(id);
    }

    canRemove(data: BusinessLineListData) {
        return true;
    }

    archive(id: string) {
        return businessLinesApi.archive(id);
    }

    canArchive(entity: BusinessLineListData) {
        return !entity.archived;
    }

    includesArchived() {
        return this.showArchived().id === 'true';
    }

    getActions(entity: BusinessLineListData): Action[] {
        let perc = Math.abs(entity.cur_phase_perc);
        if (perc + 0.000001 > 100 && perc - 0.000001 < 100) {
            return []; // already fully approved
        }

        return [
            {
                icon: 'check',
                title: i18n.t('Approve')(),
                cssClass: '',
                onClick: () => {
                    let result = new Deferred<BusinessLineListData>();

                    result.promise.then(record => {
                        let idx = this.loader.items().indexOf(entity);
                        if (idx >= 0) {
                            this.loader.items.splice(idx, 1, record);
                        }
                    });

                    app.formsStackController.push({
                        title: i18n.t('Progression checklist')(),
                        name: 'business-line-edit-checklist',
                        params: { id: entity.id, result }
                    });
                }
            }
        ];
    }
}

export let businessLinesByRole = { name: 'business-lines-by-role', viewModel: BusinessLinesByRoleScreen, template: template };

ko.components.register(businessLinesByRole.name, businessLinesByRole);
