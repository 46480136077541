import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { ProjectCategoryData, projectCategoriesApi } from '../api/simple_api';

let template = require('../../templates/project_categories.html').default;

class ProjectCategoriesScreen implements ListLoaderDelegate<ProjectCategoryData> {
    fetch(params: ListRequestParams) {
        return projectCategoriesApi.list(params);
    }

    instantiate(data: ProjectCategoryData) {
        return data;
    }

    getEditUrl(entity: ProjectCategoryData): string {
        return '/project_categories/' + entity.id + '/';
    }

    remove(id: string) {
        return projectCategoriesApi.remove(id);
    }

    canRemove(data: ProjectCategoryData) {
        return true;
    }
}

export let projectCategories = { name: 'project-categories', viewModel: ProjectCategoriesScreen, template: template };

ko.components.register(projectCategories.name, projectCategories);
