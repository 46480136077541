import * as ko from 'knockout';

import { ListRequestParams } from '@core/api/request';
import { ListLoaderDelegate } from '@core/components/list_loader';
import { RegionData, regionsApi } from '../api/simple_api';

let template = require('../../templates/regions.html').default;

class RegionsScreen implements ListLoaderDelegate<RegionData> {
    fetch(params: ListRequestParams) {
        return regionsApi.list(params);
    }

    instantiate(data: RegionData) {
        return data;
    }

    remove(id: string) {
        return regionsApi.remove(id);
    }

    canRemove(data: RegionData) {
        return true;
    }
}

export let regions = { name: 'regions', viewModel: RegionsScreen, template: template };

ko.components.register(regions.name, regions);
