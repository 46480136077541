import * as ko from 'knockout';
import { Deferred } from '@core/utils/deferred';
import { businessLinesApi } from 'api/simple_api';
import { downloadBlob } from '@core/utils';

const template = require('../../templates/components/business_line_download_report.html').default;

class BusinessLineDownloadReport {
    constructor(params: { id: string, name: string, result: Deferred<{}> }) {
        this.download(params.id, params.name, params.result);
    }

    private async download(id: string, name: string, result: Deferred<{}>) {
        try {
            const data = await businessLinesApi.exportReport(id);
            downloadBlob(data, `${name}.zip`);
        } finally {
            result.resolve(null);
        }
    }
}

export let businessLineDownloadReport = { name: 'business-line-download-report', viewModel: BusinessLineDownloadReport, template: template };

ko.components.register(businessLineDownloadReport.name, businessLineDownloadReport);
