import { FormSelectSearchConfiguration } from '@core/components/form_select_search';
import { UserData, usersApi, CountryData, countriesApi, CropData, cropsApi, ProjectCategoryData, projectCategoriesApi, PartnerData, partnersApi, FactorDefData, factorDefsApi, CropCategoryData, cropCategoriesApi, regionsApi, RegionData, HubData, hubsApi, TagData, tagsApi, UserTagData, userTagsApi, OrganizationTypeData, orgTypesApi, SiteData, sitesApi } from '../../api/simple_api';
import i18n from '@core/i18n';
import { userRole } from 'app_session';
import { KOMaybeArray } from '@core/utils/ko_utils';
import { canEditFactorDefs, canEditPartners, canEditSites, canEditTags } from 'permissions';

export function getUserSearch(observable: KOMaybeArray<UserData | null>): FormSelectSearchConfiguration<UserData> {
    return addTarget(observable, {
        getSummaryName: entity => entity.name,
        list: params => usersApi.list(params)
    });
}

export function getCountrySearch(observable: ko.Observable<CountryData | null>): FormSelectSearchConfiguration<CountryData> {
    return {
        getSummaryName: (entity) => {
            return entity.name;
        },
        list: (params) => {
            return countriesApi.list(params);
        },
        entity: observable
    };
}

export function getHubSearch(observable: ko.Observable<HubData | null>, country: ko.Observable<CountryData | null>): FormSelectSearchConfiguration<HubData> {
    return {
        getSummaryName: (entity) => {
            return entity.name;
        },
        list: (params) => {
            return hubsApi.list({ name: '', countries: country() ? [country().id] : [], ...params })
        },
        entity: observable
    };
}

export function getCropSearch(observable: KOMaybeArray<CropData>): FormSelectSearchConfiguration<CropData> {
    return addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => cropsApi.list(params)
    });
}

export function getCropCategorySearch(observable: KOMaybeArray<CropCategoryData>): FormSelectSearchConfiguration<CropCategoryData> {
    return addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => cropCategoriesApi.list(params)
    });
}

export function getProjectCategorySearch(observable: KOMaybeArray<ProjectCategoryData>): FormSelectSearchConfiguration<ProjectCategoryData> {
    let config = addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => projectCategoriesApi.list(params)
    });

    if (userRole() === 'admin') {
        config.create = { title: i18n.t('Create category')(), componentName: 'project-category-edit' };
    }

    return config;
}

export function getPartnerSearch(observable: KOMaybeArray<PartnerData>): FormSelectSearchConfiguration<PartnerData> {
    let config = addTarget(observable, {
        getSummaryName: entity => entity.name,
        list: params => partnersApi.list(params),
    });

    if (canEditPartners()) {
        config.create = { title: i18n.t('Create partner')(), componentName: 'partner-edit' };
    }

    return config;
}

export function getTagSearch(observable: KOMaybeArray<TagData>): FormSelectSearchConfiguration<TagData> {
    let config = addTarget(observable, {
        getSummaryName: entity => entity.name,
        list: params => tagsApi.list(params),
    });

    if (canEditTags()) {
        config.create = { title: i18n.t('Create tag')(), componentName: 'tag-edit' };
    }

    return config;
}

export function getFactorDefSearch(observable: KOMaybeArray<FactorDefData>, categories: ko.ObservableArray<ProjectCategoryData>, categoryId: ko.Observable<string>): FormSelectSearchConfiguration<FactorDefData> {
    let getCategory = () => {
        for (let cat of categories()) {
            if (categoryId() === cat.id) {
                return cat;
            }
        }

        return null;
    };

    let config = addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => factorDefsApi.list({ category_ids: categoryId() ? [categoryId()] : categories().map(cat => cat.id), ...params }),
    });

    if (canEditFactorDefs()) {
        config.create = { title: i18n.t('Create factor')(), componentName: 'factor-def-edit', extraParams: { getCategory } };
    }

    return config;
}

export function getRegionSearch(observable: KOMaybeArray<RegionData>): FormSelectSearchConfiguration<RegionData> {
    return addTarget(observable, {
        getSummaryName: entity => entity.name,
        list: params => regionsApi.list(params),
        create: { title: i18n.t('Create region')(), componentName: 'region-edit' }
    });
}

export function getUserTagSearch(observable: KOMaybeArray<UserTagData>): FormSelectSearchConfiguration<UserTagData> {
    let config = addTarget(observable, {
        getSummaryName: entity => entity.name,
        list: params => userTagsApi.list(params),
    });

    if (canEditTags()) {
        config.create = { title: i18n.t('Create tag')(), componentName: 'user-tag-edit' };
    }

    return config;
}

export function getOrgTypeSearch(observable: KOMaybeArray<OrganizationTypeData>): FormSelectSearchConfiguration<OrganizationTypeData> {
    return addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => orgTypesApi.list(params)
    });
}

export function getSiteSearch(observable: KOMaybeArray<SiteData>): FormSelectSearchConfiguration<SiteData> {
    let config = addTarget(observable, {
        getSummaryName: entity => entity.name_json,
        list: params => sitesApi.list(params)
    });
    if (canEditSites()) {
        config.create = { title: i18n.t('Create site')(), componentName: 'site-edit' };
    }

    return config;
}

function addTarget<T>(observable: KOMaybeArray<T>, config: FormSelectSearchConfiguration<T>): FormSelectSearchConfiguration<T> {
    if ('push' in observable) {
        config.entities = observable;
    } else {
        config.entity = observable;
    }

    return config;
}
