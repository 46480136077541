import * as ko from "knockout";

import { FileUploadDelegate } from "@core/components/basic_widgets";
import {
  CloudStorageUploadDelegate,
  CloudStorageUpload,
  FileUploadEndpoint,
} from "@core/cloud_storage_upload";
import {
  DocumentationData,
  getDocumentationUploadEndpoint,
} from "api/simple_api";

export class Documentation
  implements FileUploadDelegate, CloudStorageUploadDelegate
{
  canReuseEndpoint = false;
  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;
  accept = "*/*";

  id: string | null = null;
  name = ko.observable("").extend({ required: true, serverError: true });
  fileName = ko.observable("");
  userFileName = ko.observable("");
  url = ko.observable("");

  constructor(data?: DocumentationData) {
    if (data) {
      this.id = data.id;
      this.name(data.name);
      this.fileName(data.file_name);
      this.userFileName(data.user_file_name);
      this.url(data.url);
    }
  }

  toData(): DocumentationData {
    return {
      id: this.id,
      name: this.name(),
      file_name: this.fileName(),
      user_file_name: this.userFileName(),
    };
  }

  onFileContents(
    userFileName: string,
    fileContents: string,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ): void {
    return this.cloudUpload.onFileContents(
      userFileName,
      fileContents,
      contentType,
      prepareXHR
    );
  }

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return getDocumentationUploadEndpoint(contentType);
  }

  onFileUploaded(
    userFileName: string,
    fileName: string,
    publicURL: string,
    contentType: string
  ): void {
    console.log(publicURL);

    this.fileName(fileName);
    this.userFileName(userFileName);
    this.url(publicURL);
  }
}
