import page from 'page';

import { app } from '../app';
import { session } from '../session';
import { asArray } from '../utils';
import { showNeedPageReload } from '../api/base_request';

class ConfirmNavigation {
    static instance: ConfirmNavigation | null = null;

    constructor(public text: string, public check: () => boolean) {
    }
}

export function confirmNavigation(text: string, check: () => boolean) {
    ConfirmNavigation.instance = new ConfirmNavigation(text, check);
}

export function clearConfirmNavigation() {
    ConfirmNavigation.instance = null;
}

window.onbeforeunload = (event: Event) => {
    if (ConfirmNavigation.instance !== null && ConfirmNavigation.instance.check()) {
        event.returnValue = !!ConfirmNavigation.instance.text;
        return ConfirmNavigation.instance.text;
    }

    return undefined;
};

export function setupBaseRoutes() {
    let currentPositionInHistory = 0;
    let ignoreNextNavigationEvent = false;

    page('*', (ctx: PageJS.Context, next: () => any) => {
        // position in history excluding the navigation action currently being processed
        let previousPositionInHistory = currentPositionInHistory;
        let isNewLocation = false;

        if (!ctx.state.positionInHistory) {
            // user navigated to a new place (i.e. he didn't use back/forward buttons)
            isNewLocation = true;
            ctx.state.positionInHistory = ++currentPositionInHistory;
            if (ctx.init) {
                // initial page, save manually because
                // in this case the state is saved before
                // the dispatch, and not afterwards
                ctx.save();
            }
        }
        currentPositionInHistory = ctx.state.positionInHistory;

        if (ignoreNextNavigationEvent) {
            ignoreNextNavigationEvent = false;
            return;
        }

        if (ConfirmNavigation.instance !== null && ConfirmNavigation.instance.check() && !confirm(ConfirmNavigation.instance.text)) {
            // cancel the current navigation action

            if (isNewLocation) {
                // normal navigation (i.e. not through back/forward buttons)
                ctx.handled = false; // block page.js from pushing a new state
                currentPositionInHistory = previousPositionInHistory;
            } else {
                // navigation through back/forward buttons:
                // undo the navigation by going back/forward
                // by the same number of positions and in
                // opposite direction. Mark the flag so we won't
                // render the page again (history.go triggers onpopstate).
                ignoreNextNavigationEvent = true;
                history.go(previousPositionInHistory - ctx.state.positionInHistory);
            }

            // don't render the page (i.e. don't call next())
            return;
        }

        app.formsStackController.clear();
        next();
    });

    page('*', (ctx: PageJS.Context, next: () => any) => {
        if (showNeedPageReload()) {
            page.stop();
            // note: location.href is still the previous page at this point,
            // use the url in the ctx instead since it's the new one
            location.href = ctx.canonicalPath;
        } else {
            next();
        }
    });

    page('*', (ctx: PageJS.Context, next: () => any) => {
        app.showNavigationBar(true); // default
        next();
    });

    page('/logout/', () => {
        session.logout().then(() => {
            page.redirect('/');
        });
    });
}

export function getFiltersParams(ctx: any) {
    return { filters: parseQueryString(ctx.querystring) };
}

export function parseQueryString(queryString: string) {
    let parts = queryString.split('&');
    let parsed: { [key: string]: string | string[] } = {};

    if (!parts) {
        return parsed;
    }

    for (let part of parts) {
        let components = part.split('=');
        if (components.length > 0) {
            let key = decodeURIComponent(components[0]);
            let value = components[1] || '';
            if (parsed[key]) {
                parsed[key] = asArray(parsed[key]);
                (parsed[key] as string[]).push(value);
            } else {
                parsed[key] = value;
            }
        }
    }

    return parsed;
}

interface CURoute {
    baseName: string;
    listComponent: { name: string };
    editComponent?: { name: string };
    params?: {};
}

export function setupCreateUpdateRoutes(requireAuthorized: (ctx: PageJS.Context, next: () => any) => void, entities: CURoute[]) {
    for (let entity of entities) {
        page('/' + entity.baseName + '/', requireAuthorized, (ctx: PageJS.Context) => {
            app.currentPage({ name: entity.listComponent.name, params: { ...getFiltersParams(ctx), ...entity.params } });
        });

        const editComponent = entity.editComponent;
        if (editComponent) {
            page('/' + entity.baseName + '/new/', requireAuthorized, () => {
                app.currentPage({ name: editComponent.name, params: { id: null, ...entity.params } });
            });

            page('/' + entity.baseName + '/:id/', requireAuthorized, (ctx: PageJS.Context) => {
                app.currentPage({ name: editComponent.name, params: { id: ctx.params.id, ...entity.params } });
            });
        }
    }
}
