import * as ko from 'knockout';
import { CountryData, ProjectCategoryData, TagData, projectCategoriesApi, countriesApi, tagsApi, DashboardFilters, businessLinesApi, DashboardInnovationData, DashboardData, DashboardInnovationDetailsData } from 'api/simple_api';
import i18n from '@core/i18n';
import { FilterDelegate, Choice, staticList, YEAR_OPTIONS, filterStaticList } from '@core/components/list_filters';
import { asArray, deflateList, downloadBlob, updateLocationWithQueryString } from '@core/utils';
import { session } from '@core/session';
import { getPhaseName } from './business_lines';
import { app } from '@core/app';
import { dashboardContact } from 'components/dashboard_contact';
import { Deferred } from '@core/utils/deferred';
import { BarChartConfig } from 'ko_bindings/bar_chart';

let template = require('../../templates/dashboard_details.html').default;

interface FilterParams {
    innovation_id: string;
    category_ids: string | string[];
    country_ids: string | string[];
    tag_ids: string | string[];
    years: string | string[];
}

class DashboardDetailsScreen {
    private categoryFilters = ko.observableArray<ProjectCategoryData>();
    private countryFilters = ko.observableArray<CountryData>();
    private tagFilters = ko.observableArray<TagData>();
    private yearFilters = ko.observableArray<Choice>();
    private siteFilters = ko.observableArray<{ id : string, name: string }>();

    filters: FilterDelegate[] = [
        { list: projectCategoriesApi.list.bind(projectCategoriesApi), entities: this.categoryFilters, title: i18n.t('Categories')() },
        { list: countriesApi.list.bind(countriesApi), entities: this.countryFilters, title: i18n.t('Countries')() },
        { list: tagsApi.list.bind(tagsApi), entities: this.tagFilters, title: i18n.t('Tags')() },
        { list: staticList(YEAR_OPTIONS), entities: this.yearFilters, title: i18n.t('Years')() }
    ];
    weatherFilters: FilterDelegate[] = [
        { list: params => this.details() ? businessLinesApi.sites(this.details().id, params.search_term) : Promise.resolve([]), entities: this.siteFilters, title: i18n.t('Sites')() }
    ];

    authorized = session.authorized;

    loading = ko.observable(true);
    loadingInnovations = ko.observable(true);
    loadingDetails = ko.observable(false);
    loadingGeographies = ko.observable(false);
    private preparingReports = ko.observableArray<string>();
    private preparingTPPReports = ko.observableArray<string>();
    data = ko.observable<DashboardData>({
        title_line_1: '',
        title_line_2: '',
        subtitle: '',
        description: '',
        innovations: [],
        locations: [],
        categories_chart: { use_category_colors: false, quarters: [], groups: [] },
        phases_chart: { use_category_colors: false, quarters: [], groups: [] },
        team: []
    });
    details = ko.observable<DashboardInnovationDetailsData>();
    innovationGeographies = ko.observableArray<string>();

    chartType = ko.observable('precipitations');

    expandedRow = ko.observable<number>(null);

    mapOverlayOptions = [
        { value: 'none', text: 'None' },
        { value: 'gyga_ted_ssa', text: 'GYGA TED SSA' }
    ];
    selectedMapOverlay = ko.observable(this.mapOverlayOptions[0].value);

    showResults = ko.pureComputed(() => {
        const details = this.details();
        if (!details) {
            return false;
        }
        const developmentPhase = details.phases.find(phase => phase.id === 'development')
        return developmentPhase.approved
    });

    private disposed = false;
    private subscriptions: KnockoutSubscription[] = [];

    constructor(params: { filters: FilterParams }) {
        let categoryIds = asArray(params.filters.category_ids);
        let countryIds = asArray(params.filters.country_ids);
        let tagIds = asArray(params.filters.tag_ids);

        let categoriesPromise = projectCategoriesApi.list({});
        let countryPromise = countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);
        let tagPromise = tagIds.length > 0 ? tagsApi.list({ ids: tagIds }) : Promise.resolve<TagData[]>([]);

        Promise.all([categoriesPromise, countryPromise, tagPromise]).then(async ([categories, countries, tags]) => {
            this.categoryFilters(categories.filter(cat => categoryIds.indexOf(cat.id) >= 0));
            this.countryFilters(countries);
            this.tagFilters(tags);
            this.yearFilters(filterStaticList(params.filters.years, YEAR_OPTIONS));

            if (this.disposed) {
                return;
            }

            this.subscriptions = [
                this.categoryFilters.subscribe(this.refresh),
                this.countryFilters.subscribe(this.refresh),
                this.tagFilters.subscribe(this.refresh),
                this.yearFilters.subscribe(this.refresh)
            ];

            await this.refresh();

            if (params.filters.innovation_id) {
                const innovation = this.data().innovations.find(innovation => innovation.id === params.filters.innovation_id);
                if (innovation) {
                    this.toggleExpandRow(innovation);

                    const scrollTo = document.getElementById(`id${innovation.id}`);
                    if (scrollTo) {
                        scrollTo.scrollIntoView(true);
                    }
                };
            }
        });
    }

    dispose() {
        this.disposed = true;
        this.subscriptions.forEach(sub => sub.dispose());
    }

    private refresh = async () => {
        let filters: DashboardFilters = {
            category_ids: deflateList(this.categoryFilters),
            country_ids: deflateList(this.countryFilters),
            tag_ids: deflateList(this.tagFilters),
            years: this.yearFilters().map(y => y.id),
            partner_ids: [],
        };
        updateLocationWithQueryString(filters);

        this.loadingInnovations(true);
        this.expandedRow(null);
        try {
            this.data(await businessLinesApi.dashboard(filters));
        } finally {
            this.loading(false);
            this.loadingInnovations(false);
        }
    };

    getPhase(innovation: DashboardInnovationData): string {
        return getPhaseName(innovation.cur_phase);
    }

    getCropNames(innovation: DashboardInnovationData): string {
        return innovation.crops.join(', ') || '–';
    }

    openInnovation = (innovation: DashboardInnovationData) => {
        window.open(`/innovations/${innovation.id}/`, '_blank');
    };

    openContact = (innovation: DashboardInnovationData) => {
        app.formsStackController.push({
            title: i18n.t('Contact us')(),
            name: dashboardContact.name,
            params: { id: innovation.id, result: new Deferred<{}>() },
            showNav: true
        });
    };

    toggleExpandRow = async (innovation: DashboardInnovationData) => {
        let index = this.data().innovations.indexOf(innovation);
        if (this.expandedRow() === index) {
            this.expandedRow(null);
            this.innovationGeographies([]);
        } else {
            this.loadingDetails(true);
            this.details(null);
            this.innovationGeographies([]);
            this.siteFilters([]);
            this.expandedRow(index);
            try {
                this.details(await businessLinesApi.dashboardInnovation(innovation.id));
            } catch (_) {
                this.expandedRow(null);
            } finally {
                this.loadingDetails(false);
            }
            if (this.selectedMapOverlay() === "gyga_ted_ssa") {
                this.loadingGeographies(true);
                this.innovationGeographies((await businessLinesApi.innovationGeographies(innovation.id)).geographies);
                this.loadingGeographies(false);
            }
        }
    };

    isPreparingReport(innovation: DashboardInnovationData): boolean {
        return this.preparingReports().indexOf(innovation.id) !== -1;
    }

    isPreparingTPPReport(innovation: DashboardInnovationData): boolean {
        return this.preparingTPPReports().indexOf(innovation.id) !== -1;
    }

    downloadReport = async (innovationDetails: DashboardInnovationDetailsData) => {
        const innovation = this.data().innovations.filter(x => x.id === innovationDetails.id)[0];
        if (!innovation) {
            return;
        }

        if (this.isPreparingReport(innovation)) {
            return;
        }

        this.preparingReports.push(innovation.id);
        try {
            const data = await businessLinesApi.publicReport(innovation.id);
            downloadBlob(data, `${innovation.name}.pdf`);
        } finally {
            this.preparingReports.remove(innovation.id);
        }
    };

    canDownloadTPPReport(innovationDetails: DashboardInnovationDetailsData) {
        const innovation = this.data().innovations.filter(x => x.id === innovationDetails.id)[0];
        if (!innovation || !innovation.has_tpp) {
            return false;
        }

        return innovation.has_tpp && this.authorized();
    };

    downloadTPPReport = async (innovationDetails: DashboardInnovationDetailsData) => {
        const innovation = this.data().innovations.filter(x => x.id === innovationDetails.id)[0];
        if (!innovation || !innovation.has_tpp) {
            return;
        }

        if (this.isPreparingTPPReport(innovation)) {
            return;
        }

        this.preparingTPPReports.push(innovation.id);
        try {
            const data = await businessLinesApi.exportTPPReport(innovation.id);
            downloadBlob(data, `${innovation.name}.xlsx`);
        } finally {
            this.preparingTPPReports.remove(innovation.id);
        }
    };

    showWeather(innovationDetails: DashboardInnovationDetailsData) {
        const innovation = this.data().innovations.filter(x => x.id === innovationDetails.id)[0];
        return innovation ? !innovation.has_tpp : true;
    }

    precipitationsChart = ko.pureComputed<BarChartConfig>(() => this.filterChart(this.details()?.precipitations_chart));
    temperatureChart = ko.pureComputed<BarChartConfig>(() => this.filterChart(this.details()?.temperature_chart));

    private filterChart(data: BarChartConfig): BarChartConfig {
        const sites = this.siteFilters().map(s => s.id);

        if (!data || sites.length === 0) {
            return data;
        }

        return {
            ...data,
            datasets: data.datasets.filter(ds => sites.indexOf(ds.label) > -1)
        };
    }
}

export let dashboardDetails = { name: 'dashboard-details', viewModel: DashboardDetailsScreen, template: template };

ko.components.register(dashboardDetails.name, dashboardDetails);
