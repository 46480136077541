import * as ko from "knockout";
import i18n from "@core/i18n";
import {
  SuggestedBusinessLineData,
  CountryData,
  SUGGESTED_BUSINESS_LINE_CATEGORIES,
  SUGGESTED_BUSINESS_LINE_AREAS,
  SUGGESTED_BUSINESS_LINE_FINANCIAL_RESOURCES,
} from "../api/simple_api";
import { getCountrySearch } from "./helpers/search_configs";
import { session } from "@core/session";
import { floatWithPrecision } from "@core/utils";

export class SuggestedBusinessLine {
  id: string | null = null;
  userName = ko.observable("").extend({ required: true, serverError: true });
  userEmail = ko.observable("").extend({ required: true, serverError: true });
  name = ko.observable("").extend({ required: true, serverError: true });
  pageInCatalogue = ko.observable("");
  country = ko.observable<CountryData | null>(null).extend({ required: true });
  category = ko.observable("").extend({ required: true });
  areas = ko.observable("").extend({ required: true });
  techCompanies = ko.observable("").extend({ required: true });
  description = ko.observable("").extend({ required: true });
  maturityOfTechnology = ko.observable("").extend({ required: true });

  valueProposition = ko.observable(null).extend({ required: true });
  easeOfUse = ko.observable(null).extend({ required: true });
  yieldAndIncome = ko.observable(null).extend({ required: true });
  affordability = ko.observable(null).extend({ required: true });
  adoptability = ko.observable(null).extend({ required: true });
  policyAndRegulations = ko.observable(null).extend({ required: true });
  improveResourceUseEfficiency = ko.observable(null);
  increaseBiodiversity = ko.observable(null);
  improveSoilHealth = ko.observable(null);
  reduceEmissions = ko.observable(null);
  resilience = ko.observable(null);

  countryOfTechnologyOrigin = ko.observable("").extend({ required: true });
  financialResources = ko.observable("").extend({ required: true });
  humanResources = ko.observable("").extend({ required: true });
  categoryOfRevenueSharing = ko.observable("").extend({ required: true });
  techTestStates = ko.observable("");
  valueChain = ko.observable("");

  countrySearch = getCountrySearch(this.country);

  averageCSRA = ko.pureComputed(() => {
    const ratings = this.getCSRARatings();
    const sum = ratings.reduce((a, b) => a + b, 0);
    const setValuesLength = ratings.filter((r) => r !== 0).length;
    return sum / setValuesLength;
  });

  sumFinal = ko.pureComputed(
    () => this.getSumFinalRatings().reduce((a, b) => a + b) + this.averageCSRA()
  );

  score = ko.pureComputed(() => {
    const maxFinal = 28;
    return (this.sumFinal() / maxFinal) * 100;
  });

  averageCSRAText = ko.pureComputed(
    () =>
      `${i18n.t("Average CSRA")()}: ${
        floatWithPrecision(this.averageCSRA(), 2) || ""
      }`
  );

  sumFinalText = ko.pureComputed(() => {
    return `${i18n.t("Sum final")()}: ${
      floatWithPrecision(this.sumFinal(), 2) || ""
    }`;
  });

  scoreText = ko.pureComputed(() => {
    return `${i18n.t("Score")()}: ${
      floatWithPrecision(this.score(), 2) || ""
    }%`;
  });

  private getCSRARatings() {
    return [
      this.improveResourceUseEfficiency(),
      this.increaseBiodiversity(),
      this.improveSoilHealth(),
      this.reduceEmissions(),
      this.resilience(),
    ].map(Number);
  }

  private getSumFinalRatings() {
    return [
      this.valueProposition(),
      this.easeOfUse(),
      this.yieldAndIncome(),
      this.affordability(),
      this.adoptability(),
      this.policyAndRegulations(),
    ].map(Number);
  }

  relevanceOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: <25%")(), value: 1 },
    { title: i18n.t("2: 25%-50%")(), value: 2 },
    { title: i18n.t("3: 51%-75%")(), value: 3 },
    { title: i18n.t("4: >=76%")(), value: 4 },
  ];

  yieldAndIncomeOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: <25%")(), value: 1 },
    { title: i18n.t("2: 25%-50%")(), value: 2 },
    { title: i18n.t("3: 51%-75%")(), value: 3 },
    { title: i18n.t("4: >=76%")(), value: 4 },
  ]

  easeOfUseOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: One training plus post-requisite")(), value: 1 },
    { title: i18n.t("2: One training plus pre-requisite")(), value: 2 },
    {
      title: i18n.t("3: Multiple training with no pre or post-requisite")(),
      value: 3,
    },
    {
      title: i18n.t("4: One training with no pre or post-requisite")(),
      value: 4,
    },
  ];

  potentialAffordabilityOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: 2.0<PBP<2.5")(), value: 1 },
    { title: i18n.t("2: 1.5<PBP<2.0")(), value: 2 },
    { title: i18n.t("3: 1.0<PBP<1.5")(), value: 3 },
    { title: i18n.t("4: <1")(), value: 4 },
  ];

  adaptabilityOptions = [
    { title: i18n.t("Select")(), value: null },
    {
      title: i18n.t(
        "1: No, technology applicability is limited to one agroclimatic zone or single farming system"
      )(),
      value: 1,
    },
    {
      title: i18n.t(
        "2: No, technology applicability is limited to not more than two agroclimatic zones and farming systems"
      )(),
      value: 2,
    },
    {
      title: i18n.t(
        "3: No, technology can be applied in more than two agroclimatic zones in limited farming systems"
      )(),
      value: 3,
    },
    {
      title: i18n.t(
        "4: Yes, technology can be applied in all existing agroclimatic zones in all farming systems"
      )(),
      value: 4,
    },
  ];

  policyAndRegulationsOptions = [
    { title: i18n.t("Select")(), value: null },
    {
      title: i18n.t("1: There is a restriction to the use of technology")(),
      value: 1,
    },
    {
      title: i18n.t("2: No restrictions and not addressed by the government")(),
      value: 2,
    },
    { title: i18n.t("3: Technology is a government priority")(), value: 3 },
    {
      title: i18n.t("4: Technology is incentivized by the government")(),
      value: 4,
    },
  ];

  improvesResourceUseEfficiencyOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: <25%")(), value: 1 },
    { title: i18n.t("2: 25%-50%")(), value: 2 },
    { title: i18n.t("3: 51%-75%")(), value: 3 },
    { title: i18n.t("4: >=76%")(), value: 4 },
  ];

  environmentallyFriendlyOptions = [
    { title: i18n.t("Select")(), value: null },
    {
      title: i18n.t(
        "1: Potentially harmful to all components of the environment i.e., water, air, plant, and soil macro- and micro-organisms"
      )(),
      value: 1,
    },
    {
      title: i18n.t(
        "2: Potentially harmful to three out of four components of the environment"
      )(),
      value: 2,
    },
    {
      title: i18n.t(
        "3: Potentially harmful to two out of four components of the environment"
      )(),
      value: 3,
    },
    {
      title: i18n.t(
        "4: Potentially harmful to one or none of the four components of the environment"
      )(),
      value: 4,
    },
  ];

  improvesSoilHealthOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: 1 out of 4")(), value: 1 },
    { title: i18n.t("2: 2 out of 4")(), value: 2 },
    { title: i18n.t("3: 3 out of 4")(), value: 3 },
    { title: i18n.t("4: 4 out of 4")(), value: 4 },
  ];

  mitigateGHGEmissionsOptions = [
    { title: i18n.t("Select")(), value: null },
    { title: i18n.t("1: Mitigates none")(), value: 1 },
    { title: i18n.t("2: Mitigates 1 out of 3")(), value: 2 },
    { title: i18n.t("3: Mitigates 2 out of 3")(), value: 3 },
    { title: i18n.t("4: Mitigates 3 out of 3")(), value: 4 },
  ];

  buildsOrPotentiallyImprovesResilience = [
    { title: i18n.t("Select")(), value: null },
    {
      title: i18n.t("1: No climatic, pest and disease and water management")(),
      value: 1,
    },
    {
      title: i18n.t(
        "2: Water management (drip irrigation over continuous flooding, etc.)"
      )(),
      value: 2,
    },
    {
      title: i18n.t("3: Soil-related shocks (salinity, acidification, etc.)")(),
      value: 3,
    },
    {
      title: i18n.t(
        "4: Climatic (erratic rainfall, drought, flood, heat, etc.) or pest and disease shocks or both"
      )(),
      value: 4,
    },
  ];

  categoryOptions = [
    { title: i18n.t("Select")(), value: "" },
    ...SUGGESTED_BUSINESS_LINE_CATEGORIES,
  ];

  areaOptions = [
    { title: i18n.t("Select")(), value: "" },
    ...SUGGESTED_BUSINESS_LINE_AREAS,
  ];

  maturityOfTechnologyOptions = [
    { title: i18n.t("Select")(), value: "" },
    { title: i18n.t("Commercial"), value: "commercial" },
    { title: i18n.t("Beta version"), value: "beta" },
  ];

  countryOfOriginOptions = [
    { title: i18n.t("Select")(), value: "" },
    { title: i18n.t("Local")(), value: "local" },
    { title: i18n.t("Overseas")(), value: "overseas" },
  ];

  financialResourcesOptions = [
    { title: i18n.t("Select")(), value: "" },
    ...SUGGESTED_BUSINESS_LINE_FINANCIAL_RESOURCES,
  ];

  humanResourcesOptions = [
    { title: i18n.t("Select")(), value: "" },
    { title: i18n.t("SFSA")(), value: "sfsa" },
    { title: i18n.t("Consultant")(), value: "consultant" },
    { title: i18n.t("Technology owner")(), value: "technology_owner" },
    { title: i18n.t("Shared")(), value: "shared" },
  ];

  categoryOfRevenueSharingOptions = [
    { title: i18n.t("Select")(), value: "" },
    { title: i18n.t("Distributor")(), value: "distributor" },
    {
      title: i18n.t("Adaptation & commercialization")(),
      value: "adapt_and_commercialization",
    },
  ];

  constructor(data: SuggestedBusinessLineData) {
    if (!data) {
      this.userName(session.getName());
      this.userEmail(session.getEmail());
      return;
    }

    this.id = data.id || null;
    this.userName(data.user_name);
    this.userEmail(data.user_email);
    this.name(data.name);
    this.country(data.country);

    this.pageInCatalogue(data.page_in_catalogue);
    this.category(data.category);
    this.areas(data.areas);
    this.techCompanies(data.tech_companies);
    this.description(data.description);
    this.maturityOfTechnology(data.maturity_of_the_technology);

    this.valueProposition(data.value_proposition);
    this.easeOfUse(data.ease_of_use);
    this.yieldAndIncome(data.yield_and_income);
    this.affordability(data.affordability);
    this.adoptability(data.adoptability);
    this.policyAndRegulations(data.policy_and_regulations);
    this.improveResourceUseEfficiency(data.improve_resource_use_efficiency);
    this.increaseBiodiversity(data.increase_biodiversity);
    this.improveSoilHealth(data.improve_soil_health);
    this.reduceEmissions(data.reduce_emissions);
    this.resilience(data.resilience);

    this.countryOfTechnologyOrigin(data.country_of_technology_origin);
    this.financialResources(data.financial_resources);
    this.humanResources(data.human_resources);
    this.categoryOfRevenueSharing(data.category_of_revenue_sharing);
    this.techTestStates(data.tech_test_states);
    this.valueChain(data.value_chain);
  }

  toData(): SuggestedBusinessLineData {
    return {
      id: this.id,
      name: this.name(),
      user_name: this.userName(),
      user_email: this.userEmail(),
      country: this.country(),

      page_in_catalogue: this.pageInCatalogue(),
      category: this.category(),
      areas: this.areas(),
      tech_companies: this.techCompanies(),
      description: this.description(),
      maturity_of_the_technology: this.maturityOfTechnology(),

      value_proposition: this.valueProposition(),
      ease_of_use: this.easeOfUse(),
      yield_and_income: this.yieldAndIncome(),
      affordability: this.affordability(),
      adoptability: this.adoptability(),
      policy_and_regulations: this.policyAndRegulations(),
      improve_resource_use_efficiency: this.improveResourceUseEfficiency(),
      increase_biodiversity: this.increaseBiodiversity(),
      improve_soil_health: this.improveSoilHealth(),
      reduce_emissions: this.reduceEmissions(),
      resilience: this.resilience(),

      country_of_technology_origin: this.countryOfTechnologyOrigin(),
      financial_resources: this.financialResources(),
      human_resources: this.humanResources(),
      category_of_revenue_sharing: this.categoryOfRevenueSharing(),
      tech_test_states: this.techTestStates(),
      value_chain: this.valueChain(),
    };
  }
}
