import * as ko from 'knockout';
import i18n from '../i18n';


(<any>ko.validation.rules)['geoPt'] = {
    validator: (val: string) => {
        if (val) {
            let parts = val.split(',');

            if (parts.length != 2) {
                return false;
            }

            for (let part of parts) {
                if (!part.trim().match(/^(-|\+)?\d+(\.\d+)?$/)) {
                    return false;
                }
            }
        }

        return true;
    },
    message: i18n.t('Invalid GPS coordinates')()
};
