import * as ko from 'knockout';

import { ProjectCategoryData, getProjectCategoryPictureUploadEndpoint } from '../api/simple_api';
import { I18nText, asI18nText } from '@core/i18n_text';
import { ImageUploadDelegate, ImageUpload } from '@core/image_upload';
import { FileUploadEndpoint } from '@core/cloud_storage_upload';

export class ProjectCategory implements ImageUploadDelegate {
    private id: string | null = null;

    nameJson = ko.observable<I18nText>(asI18nText('')).extend({
        i18nTextRequired: true
    });
    code = ko.observable('').extend({ required: true });
    description = ko.observable('');

    pictureUpload = new ImageUpload(this);

    constructor(data: ProjectCategoryData) {
        if (data) {
            this.id = data.id;
            this.nameJson(data.name_json);
            this.code(data.code);
            this.description(data.description);
            this.pictureUpload.fileName = data.picture;
            this.pictureUpload.picturePublicURL(data.picture_url);
        }
    }

    getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
        return getProjectCategoryPictureUploadEndpoint(contentType);
    }

    toData(): ProjectCategoryData {
        return {
            id: this.id,
            name_json: this.nameJson(),
            code: this.code(),
            description: this.description(),
            picture: this.pictureUpload.fileName || null
        };
    }
}
